@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bodyColor: #222222;
  --bodyBg: #fff;
  --whiteColor: #ffffff;
  --blackColor: #032a3d;
  --blackBlue: #ffb338;
  --headingColor: #222222;
  --contentColor: #5f6c76;
  --primaryColor: #fff;
  --secondaryColor: #18239d;
  --greyColor: #ededed;
  --borderColor: #eeeeee;
  --borderColor2: #eacadf;
  --borderColor3: #ccc;
  --navyBlue: #18239d;
  --navyBlue2: #221c49;
  --pinkcolor: #fff0fa;
  --violet: #6e1299;
  --yellow: #ff912c;
  --gradientColor: linear-gradient(90deg, #006496 0%, #ffb338 100%);

  --gradientColor2: linear-gradient(
    180deg,
    rgba(0, 100, 150, 0.2) 0%,
    #08152a 100%
  );
  .blog__details ul li {
    display: block !important;
  }

  --gradientColor3: linear-gradient(
    90deg,
    #006496 0%,
    rgba(255, 179, 56, 0.2) 100%
  );
  --borderRadius: 8px;
  --borderRadius2: 5px;
  --transition: 0.3s;
  --fontSource: "Source Sans Pro";
  --boxShadow1: 0 0 20px 5px rgba(95, 45, 237, 0.1);
}

/* .is_dark {
  --bodyColor: #fff;
  --bodyBg: #0c0e2b;
  --headingColor: #fff;
  --contentColor: #fff;
  --blackColor: #fff;
  --whiteColor: #0c0e2b;
  --borderColor: #312a57;
  --borderColor2: #342a51;
  --darkShadow: 0 0 20px 5px rgba(95, 45, 237, 0.1);
  --pinkcolor: #170f2f;
  --blackBlue: #fff;
  --navyBlue2: #fff;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--bodyBg);
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
  font-family: var(--font-poppins);
}

p {
  font-size: 17px;
  margin-bottom: 15px;
  color: var(--contentColor);
}
html,
body {
}
a,
button {
  text-decoration: none;
  transition: var(--transition);
}

p,
a,
.btn,
button,
p,
input,
select,
textarea,
li,
img {
  -webkit-transition: all var(--transition) ease-out 0s;
  -moz-transition: all var(--transition) ease-out 0s;
  -ms-transition: all var(--transition) ease-out 0s;
  -o-transition: all var(--transition) ease-out 0s;
  transition: all var(--transition) ease-out 0s;
}
.default__button {
  position: relative;
  z-index: 100;
}
img {
  height: auto;
}
a,
button {
  color: var(--contentColor);
}
.headerarea__cart__dropdown__button a,
nav > ul > li > a {
  margin: 0px 2.5px;
}
.offcanvas__header nav ul > li > a {
  margin: 0px;
}
a:hover {
  color: var(--primaryColor);
}

input:focus-visible {
  outline: none;
}
button {
  border: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headingColor);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: var(--blackColor);
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
  display: inline-block;
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .container {
    max-width: 1355px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
  --bs-gutter-x: 1.094rem;
}

.container-fluid.full__width__padding {
  padding: 0 125px;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .container-fluid.full__width__padding {
    padding: 0 150px;
  }
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .container-fluid.full__width__padding {
    padding: 0 50px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .container-fluid.full__width__padding {
    padding: 0 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container-fluid.full__width__padding {
    padding: 0 25px;
  }
}
@media (max-width: 767px) {
  .container-fluid.full__width__padding {
    padding: 0 20px;
  }
}

.container-fluid.padding {
  padding: 0 80px;
}

.row {
  --bs-gutter-x: 2.188rem;
}

.column__custom__class {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.row__custom__class {
  margin-left: -10px !important;
  margin-right: -10px !important;
  padding: 0;
}

@media (min-width: 992px) and (max-width: 1365px),
  (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .special__spacing .row {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
}
@media (min-width: 992px) and (max-width: 1365px),
  (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .special__spacing [class*="col-"] {
    padding: 0 5px !important;
  }
}

.overflow__hidden__X {
  overflow-x: hidden;
}

.sp_100 {
  padding: 100px 0;
}

.sp_90 {
  padding: 90px 0;
}

.sp_80 {
  padding: 80px 0;
}

.sp_70 {
  padding: 70px 0;
}

.sp_60 {
  padding: 60px 0;
}

.sp_50 {
  padding: 50px 0;
}

.sp_40 {
  padding: 40px 0;
}

.sp_30 {
  padding: 30px 0;
}

.sp_20 {
  padding: 20px 0;
}

.sp_top_250 {
  padding-top: 250px;
}
@media (max-width: 767px) {
  .sp_top_250 {
    padding-top: 150px;
  }
}

.sp_top_200 {
  padding-top: 200px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_top_200 {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  .sp_top_200 {
    padding-top: 200px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .sp_top_200 {
    padding-top: 300px;
  }
}

.sp_top_190 {
  padding-top: 190px;
}

.sp_top_180 {
  padding-top: 180px;
}

.sp_top_170 {
  padding-top: 170px;
}

.sp_top_160 {
  padding-top: 160px;
}

.sp_top_150 {
  padding-top: 150px;
}

.sp_top_130 {
  padding-top: 130px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_top_130 {
    padding-top: 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_top_130 {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .sp_top_130 {
    padding-top: 50px;
  }
}

.sp_top_140 {
  padding-top: 140px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .sp_top_140 {
    padding-top: 120px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_top_140 {
    padding-top: 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_top_140 {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .sp_top_140 {
    padding-top: 50px;
  }
}

.sp_top_120 {
  padding-top: 120px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_top_120 {
    padding-top: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_top_120 {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .sp_top_120 {
    padding-top: 60px;
  }
}

.sp_top_100 {
  padding-top: 100px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_top_100 {
    padding-top: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_top_100 {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .sp_top_100 {
    padding-top: 40px;
  }
}

.sp_top_90 {
  padding-top: 90px;
}

.sp_top_80 {
  padding-top: 80px;
}

.sp_top_70 {
  padding-top: 70px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_top_70 {
    padding-top: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_top_70 {
    padding-top: 20px;
  }
}
@media (max-width: 767px) {
  .sp_top_70 {
    padding-top: 10px;
  }
}

.sp_top_60 {
  padding-top: 60px;
}

.sp_top_50 {
  padding-top: 50px;
}

.sp_top_40 {
  padding-top: 40px;
}

.sp_top_30 {
  padding-top: 30px;
}

.sp_top_20 {
  padding-top: 20px;
}

.sp_bottom_330 {
  padding-bottom: 330px;
}
@media (max-width: 767px) {
  .sp_bottom_330 {
    padding-bottom: 150px;
  }
}

.sp_bottom_270 {
  padding-bottom: 270px;
}

.sp_bottom_200 {
  padding-bottom: 200px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_200 {
    padding-bottom: 130px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_200 {
    padding-bottom: 130px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_200 {
    padding-bottom: 100px;
  }
}

.sp_bottom_190 {
  padding-bottom: 190px;
}

.sp_bottom_180 {
  padding-bottom: 180px;
}

.sp_bottom_170 {
  padding-bottom: 170px;
}

.sp_bottom_160 {
  padding-bottom: 160px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_160 {
    padding-bottom: 120px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_160 {
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_160 {
    padding-bottom: 90px;
  }
}

.sp_bottom_150 {
  padding-bottom: 150px;
}

.sp_bottom_140 {
  padding-bottom: 140px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_140 {
    padding-bottom: 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_140 {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_140 {
    padding-bottom: 70px;
  }
}

.sp_bottom_110 {
  padding-bottom: 110px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_110 {
    padding-bottom: 70px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_110 {
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_110 {
    padding-bottom: 40px;
  }
}

.sp_bottom_130 {
  padding-bottom: 130px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_130 {
    padding-bottom: 90px;
    padding-top: 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_130 {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_130 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.sp_bottom_120 {
  padding-bottom: 120px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_120 {
    padding-bottom: 90px;
    padding-top: 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_120 {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_120 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.sp_bottom_100 {
  padding-bottom: 100px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_100 {
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_100 {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_100 {
    padding-bottom: 40px;
  }
}

.sp_bottom_90 {
  padding-bottom: 90px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_90 {
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_90 {
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_90 {
    padding-bottom: 60px;
  }
}

.sp_bottom_80 {
  padding-bottom: 80px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_80 {
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_80 {
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_80 {
    padding-bottom: 20px;
  }
}

.sp_bottom_70 {
  padding-bottom: 70px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_70 {
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_70 {
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_70 {
    padding-bottom: 30px;
  }
}

.sp_bottom_60 {
  padding-bottom: 60px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sp_bottom_60 {
    padding-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_60 {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_60 {
    padding-bottom: 10px;
  }
}

.sp_bottom_50 {
  padding-bottom: 50px;
}

.sp_bottom_40 {
  padding-bottom: 40px;
}

.sp_bottom_30 {
  padding-bottom: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sp_bottom_30 {
    padding-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .sp_bottom_30 {
    padding-bottom: 5px;
  }
}

.sp_bottom_20 {
  padding-bottom: 20px;
}

.col--30 {
  padding-left: 30px;
  padding-right: 30px;
}

.height__700 {
  height: 700px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .height__700 {
    height: 700px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .height__700 {
    height: 550px;
  }
}
@media (max-width: 767px) {
  .height__700 {
    height: 450px;
  }
}

.height__800 {
  height: 800px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .height__800 {
    height: 700px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .height__800 {
    height: 550px;
  }
}
@media (max-width: 767px) {
  .height__800 {
    height: 450px;
  }
}

.height__900 {
  height: 900px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .height__900 {
    height: 700px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .height__900 {
    height: 550px;
  }
}
@media (max-width: 767px) {
  .height__900 {
    height: 450px;
  }
}

.height__950 {
  height: 950px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .height__950 {
    height: 680px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .height__950 {
    height: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .height__950 {
    height: 800px;
  }
}
@media (max-width: 767px) {
  .height__950 {
    height: 700px;
  }
}

.height__970 {
  height: 970px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .height__970 {
    height: 800px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .height__970 {
    height: 700px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .height__970 {
    height: 700px;
  }
}

.height__1028 {
  height: 1028px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .height__1028 {
    height: 750px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .height__1028 {
    height: 550px;
  }
}
@media (min-width: 1200px) and (max-width: 1365px) {
  .height__1028 {
    height: 660px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .height__1028 {
    height: 430px;
  }
}
@media (max-width: 767px) {
  .height__1028 {
    height: auto;
  }
}

.pink__bg__color {
  background-color: var(--whiteColor);
}

.bg__pink {
  background: var(--blackColor);
}

.default__button {
  padding: 20px 45px;
  background: var(--blackColor);
  color: var(--whiteColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: var(--borderRadius);
  transition: 0.3s;
  display: inline-block;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .default__button {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .default__button {
    padding: 20px 19px;
  }
}
.default__button:hover {
  -webkit-filter: brightness(150%);
  filter: brightness(150%);
  color: var(--whiteColor);
}
.default__button.btn__blue {
  background: var(--navyBlue);
  padding: 20px 50px;
  border: 1px solid var(--navyBlue);
}
.default__button.btn__blue:hover {
  background: transparent;
  color: var(--navyBlue);
}
.default__button.btn__black {
  background: var(--blackColor);
  border: 1px solid var(--blackColor);
}
.default__button.btn__black:hover {
  background: transparent;
  color: var(--blackColor);
}
.default__button.btn__white {
  background: var(--whiteColor);
  color: var(--blackColor);
  border: 1px solid var(--whiteColor);
}
.default__button.btn__white:hover {
  background: transparent;
  color: var(--whiteColor);
}
.default__button.btn__pink {
  background: var(--pinkcolor);
  color: var(--blackColor);
  border: 1px solid var(--pinkcolor);
}
.default__button.btn__pink:hover {
  background: transparent;
  color: var(--pinkcolor);
}

button.default__button {
  border: none;
}

.direction__btn {
  height: 62px;
  width: 62px;
  line-height: 62px;
  background: var(--whiteColor);
  text-align: center;
  display: inline-block;
  border-radius: 100%;
  position: relative;
}
@media (max-width: 767px) {
  .direction__btn {
    height: 45px;
    width: 45px;
    line-height: 45px;
  }
}
.direction__btn:hover::after {
  opacity: 1;
}
.direction__btn:hover svg path {
  stroke: var(--whiteColor);
}
.direction__btn::after {
  background: var(--blackColor);
  position: absolute;
  border-radius: 100%;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  opacity: 0;
  z-index: 9;
}
.direction__btn svg {
  position: relative;
  z-index: 99;
}
.direction__btn svg path {
  stroke: var(--blackColor);
  transition: var(--transition);
}
.direction__btn--2 {
  background: none;
}
.direction__btn--2::after {
  opacity: 1;
}
.direction__btn--2 svg path {
  stroke: var(--whiteColor);
}
.direction__btn--2:hover {
  background: var(--whiteColor);
}
.direction__btn--2:hover::after {
  opacity: 0;
}
.direction__btn--2:hover svg path {
  stroke: var(--whiteColor);
}
.direction__btn--2:hover svg path {
  stroke: var(--blackColor);
}

.gradient__bg {
  background: var(--gradientColor);
}

.white__bg {
  background: var(--whiteColor);
}

.common__gradient__bg {
  position: relative;
}
.common__gradient__bg::after {
  background-color: rgb(17 24 39 / 95%);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  opacity: 0;
  border-radius: var(--borderRadius);
}
.common__gradient__bg:hover::after {
  opacity: 1;
}

@media (max-width: 767px) {
  .section__title.sp_bottom_90 {
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section__title.sp_bottom_50 {
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .section__title.sp_bottom_50 {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .section__title {
    margin-bottom: 20px;
  }
}
.section__title__button {
  margin-bottom: 20px;
}
.section__title__button span {
  color: var(--blackColor);
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  border: 1px solid var(--borderColor2);
  padding: 10px 26px;
  border-radius: 50px;
  display: inline-block;
}
.section__title__heading {
  margin-bottom: 30px;
  max-width: 50%;
  margin: 0 auto;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .section__title__heading {
    max-width: 54%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section__title__heading {
    max-width: 72%;
  }
}
@media (max-width: 767px) {
  .section__title__heading {
    max-width: 100%;
  }
}
.section__title__heading h3 {
  color: var(--blackColor);
  text-align: center;
  font-size: 37px;
  font-weight: 700;
  line-height: 50px;
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .section__title__heading h3 {
    font-size: 29px;
    line-height: 45px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section__title__heading h3 {
    font-size: 27px;
    line-height: 37px;
  }
}
@media (max-width: 767px) {
  .section__title__heading h3 {
    font-size: 24px;
    line-height: 36px;
  }
}
.section__title__heading h3 span {
  color: var(--primaryColor);
}
.section__title__text {
  max-width: 75%;
  margin: 0 auto;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .section__title__text {
    max-width: 55%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section__title__text {
    max-width: 74%;
  }
}
@media (max-width: 767px) {
  .section__title__text {
    max-width: 100%;
  }
}
.section__title__text p {
  color: var(--contentColor);
  text-align: center;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
}
.section__title--2 .section__title__heading {
  max-width: 100%;
}
.section__title--2 .section__title__heading h3 {
  text-align: left;
}
.section__title--2 .section__title__text {
  max-width: 100%;
}
.section__title--2 .section__title__text p {
  text-align: left;
}
.section__title--3 .section__title__heading h3 {
  color: var(--whiteColor);
}
.section__title--3 .section__title__button span {
  color: var(--whiteColor);
}
.section__title--3 .section__title__text__3 p {
  color: var(--whiteColor);
  margin: 0;
}
.section__title--3 .blog__4__section__title__text p {
  color: var(--whiteColor);
}
.section__title__small span {
  font-size: 15px;
  font-weight: 700;
  color: var(--primaryColor);
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  display: inline-block;
}
.section__title__small span::before {
  position: absolute;
  content: "";
  height: 4px;
  width: 30px;
  background: var(--primaryColor);
  top: 50%;
  left: 0;
  margin-top: -2px;
}
.section__title--4 .section__title__small span {
  color: var(--whiteColor);
}
.section__title--4 .section__title__small span::before {
  background: var(--whiteColor);
}
.section__title--4 .section__title__heading h3 {
  color: var(--whiteColor);
}
.section__title__btn {
  margin-top: 38px;
}

.text-start .section__title__heading h3 {
  text-align: left;
}

.text-start .section__title__heading {
  margin-left: 0;
}

.text-start .section__title__text p {
  text-align: left;
}

.text-start .section__title__text {
  margin-left: 0;
}

.text-end .section__title__heading h3 {
  text-align: right;
}

.text-end .section__title__heading {
  margin-right: 0;
}

.text-end .section__title__text p {
  text-align: right;
}

.text-end .section__title__text {
  margin-right: 0;
}

.text__gradient {
  -webkit-text-fill-color: transparent;
  background: var(--gradientColor);
  -webkit-background-clip: text;
}

.text__unerline {
  position: relative;
}
.text__unerline::after {
  position: absolute;
  background: var(--primaryColor);
  height: 2px;
  width: 100%;
  content: "";
  left: 0;
  bottom: 0;
}

.bastun__brand__badge {
  display: inline-block;
}
@media (max-width: 767px) {
  .bastun__brand__badge__inner {
    max-width: 60%;
    margin: auto;
  }
  .bastun__brand__badge__inner img {
    width: 100%;
  }
  .bastun__brand__badge__inner img.bbb__icon {
    width: 30px;
  }
}
.bastun__brand__badge__footer {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translate(-50%);
}
.bastun__brand__badge .bbb__animate {
  animation: animationglob 10s cubic-bezier(1, 0.99, 0.03, 0.01) infinite;
}
.bastun__brand__badge .bbb__animate:hover {
  animation-play-state: paused;
}
.bastun__brand__badge .bbb__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.single__transform {
  transition: var(--transition);
}
.single__transform:hover {
  transform: translateY(-10px);
}

.ssla__animation {
  position: absolute;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}

.border__line {
  border: 1px dashed var(--borderColor);
  max-width: 1550px;
  margin: auto;
}

@keyframes dot-anim {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes dot-anim-2 {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes up-do-anim {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes cir-anim {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes move5 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes animationglob {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.theme__shadow__circle {
  position: fixed;
  left: -100px;
  top: 100px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 1000px;
  height: 1000px;
  background-image: linear-gradient(
    45deg,
    var(--secondaryColor),
    var(--primaryColor)
  );
  opacity: 0.2;
  -webkit-filter: blur(100px);
  filter: blur(100px);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .theme__shadow__circle {
    width: 100%;
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .theme__shadow__circle {
    width: 100%;
    left: 0;
  }
}
@media (max-width: 767px) {
  .theme__shadow__circle {
    width: 100%;
    left: 0;
  }
}
.theme__shadow__circle.shadow__right {
  left: auto;
  top: 50px;
  right: -100px;
  bottom: auto;
  background-image: linear-gradient(
    45deg,
    var(--primaryColor) 10%,
    var(--secondaryColor) 50%
  );
}
@media (min-width: 992px) and (max-width: 1365px) {
  .theme__shadow__circle.shadow__right {
    right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .theme__shadow__circle.shadow__right {
    right: 0;
  }
}
@media (max-width: 767px) {
  .theme__shadow__circle.shadow__right {
    right: 0;
  }
}
.theme__shadow__circle--2 {
  position: absolute;
  left: 0;
  opacity: 0.1;
}
.theme__shadow__circle--2.shadow__right {
  right: 0;
}

.bg__black {
  background: var(--blackColor);
}

.swiper-slide[class*="col-"] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.pagination {
  margin-top: 40px;
}
.swiper-button-prev svg,
.swiper-button-next svg {
  max-width: 24px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .pagination {
    margin-top: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .pagination {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .pagination {
    margin-top: 20px;
  }
}
.pagination ul li {
  margin-right: 10px;
}
.pagination ul li a {
  height: 56px;
  width: 56px;
  line-height: 56px;
  text-align: center;
  background: var(--whiteColor);
  display: inline-block;
  border-radius: var(--borderRadius);
  font-weight: 500;
  color: var(--blackColor);
  border: 1px solid var(--borderColor);
}
.pagination ul li a:hover {
  color: var(--whiteColor);
}
.pagination ul li a span {
  position: relative;
  z-index: 99;
}
.pagination ul li a.active {
  color: var(--whiteColor);
}
.pagination ul li a.active::after {
  opacity: 1;
}

.slider__controls__pagination .swiper-pagination .swiper-pagination-bullet {
  height: 12px;
  width: 12px;
  background: var(--greyColor);
  opacity: 1;
}

.slider__controls__pagination .swiper-pagination {
  bottom: -90px;
}
@media (max-width: 767px) {
  .slider__controls__pagination .swiper-pagination {
    bottom: -50px;
  }
}

.slider__controls__pagination
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--gradientColor);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.slider__controls__arrows .arrow-btn {
  font-size: 40px;
  color: var(--whiteColor);
  height: 65px;
  width: 65px;
  border: 2px solid var(--whiteColor);
  border-radius: 100px;
  position: absolute;
  transition: var(--transition);
  line-height: 65px;
  text-align: center;
  left: 0;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .slider__controls__arrows .arrow-btn {
    left: -60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider__controls__arrows .arrow-btn {
    height: 50px;
    width: 50px;
  }
}
@media (max-width: 767px) {
  .slider__controls__arrows .arrow-btn {
    height: 50px;
    width: 50px;
  }
}
.slider__controls__arrows .arrow-btn:hover {
  border: 2px solid transparent;
}
.slider__controls__arrows .arrow-btn:hover::before {
  opacity: 1;
}
.slider__controls__arrows .arrow-btn:hover svg {
  stroke: var(--whiteColor);
  z-index: 999;
}
.slider__controls__arrows .arrow-btn::before {
  background: var(--gradientColor);
  position: absolute;
  border-radius: 100%;
  left: -3px;
  top: -3px;
  height: 67px;
  width: 67px;
  content: "";
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  opacity: 0;
  z-index: 9;
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider__controls__arrows .arrow-btn::before {
    height: 50px;
    width: 50px;
  }
}
@media (max-width: 767px) {
  .slider__controls__arrows .arrow-btn::before {
    height: 50px;
    width: 50px;
  }
}
.slider__controls__arrows .arrow-btn svg {
  transition: var(--transition);
}
.slider__controls__arrows .arrow-btn i {
  position: relative;
  z-index: 9999;
}
.slider__controls__arrows .arrow-btn.swiper-button-next {
  left: auto;
  right: 0;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .slider__controls__arrows .arrow-btn.swiper-button-next {
    right: -60px;
  }
}
.slider__controls__arrows .arrow-btn-2 {
  border: 2px solid #fff0fa;
}
.slider__controls__arrows .arrow-btn-2 svg {
  z-index: 999;
}
.slider__controls__arrows .arrow-btn-2 svg path {
  stroke: var(--blackColor);
}
.slider__controls__arrows .arrow-btn-2:hover svg path {
  stroke: var(--whiteColor);
}
.slider__controls__arrows .arrow-btn-3 {
  border: 2px solid var(--blackColor);
}
.slider__controls__arrows .arrow-btn-3 svg {
  z-index: 999;
}
.slider__controls__arrows .arrow-btn-3 svg path {
  stroke: var(--blackColor);
}
.slider__controls__arrows .arrow-btn-3:hover svg path {
  stroke: var(--whiteColor);
}

.slider__controls__arrows--2 {
  position: absolute;
  right: -130px;
  top: 58%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .slider__controls__arrows--2 {
    right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider__controls__arrows--2 {
    right: 0;
  }
}
@media (max-width: 767px) {
  .slider__controls__arrows--2 {
    right: 0;
  }
}
.slider__controls__arrows--2 .arrow-btn {
  right: 0;
  left: auto;
}
.slider__controls__arrows--2 .arrow-btn.swiper-button-next {
  top: -90px;
  right: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider__controls__arrows--2 .arrow-btn.swiper-button-next {
    top: -60px;
  }
}
@media (max-width: 767px) {
  .slider__controls__arrows--2 .arrow-btn.swiper-button-next {
    top: -60px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .slider__controls__arrows--2 .arrow-btn.arrow-btn-2 {
    background: var(--whiteColor);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider__controls__arrows--2 .arrow-btn.arrow-btn-2 {
    background: var(--whiteColor);
  }
}
@media (max-width: 767px) {
  .slider__controls__arrows--2 .arrow-btn.arrow-btn-2 {
    background: var(--whiteColor);
  }
}

.slider__controls__arrows--3 .swiper-button-next.arrow-btn {
  right: 20px;
  top: -123px;
}

.slider__controls__arrows--3 .swiper-button-prev.arrow-btn {
  left: auto;
  right: 110px;
  top: -123px;
}

.slider__control__top__wrap {
  height: 100%;
}

.modal__wraper {
  max-width: 1000px;
  margin: 150px auto;
  max-height: 750px;
  overflow: auto;
  overflow-x: hidden;
}

@media (min-width: 0px) and (max-width: 991px) {
  .slider__controls__arrows--3 .swiper-button-next.arrow-btn,
  .slider__controls__arrows--3 .swiper-button-prev.arrow-btn {
    top: -70px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .slider__controls__arrows--3 .swiper-button-next.arrow-btn,
  .slider__controls__arrows--3 .swiper-button-prev.arrow-btn {
    top: -100px;
  }
}
@media (min-width: 992px) and (max-width: 1365px),
  (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .modal__wraper {
    margin: 50px auto;
  }
}
.modal__wraper .modal-content {
  padding: 15px;
  background: var(--whiteColor);
}
.modal__wraper .modal-body {
  padding: 0;
}
.modal__wraper .btn-close {
  position: absolute;
  right: 10px;
  z-index: 99;
  background-color: var(--whiteColor);
}

#back__preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: -webkit- flex;
  display: -ms- flex;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  background: var(--whiteColor);
  position: fixed;
  z-index: 999999;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.back__loader_logo {
  position: absolute;
}

.back__loader_logo img {
  width: 40px;
  height: auto;
}

#back__circle_loader {
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 5px;
  border-top-color: #465fe9;
  border-right-color: #465fe9;
  border-bottom-color: #d5eaff;
  border-left-color: #d5eaff;
  border-radius: 50%;
  -webkit-animation: spinLoader 2s linear infinite;
  animation: spinLoader 2s linear infinite;
}

@-webkit-keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.mode_switcher.my_switcher {
  position: fixed;
  right: -50px;
  top: 100px;
  z-index: 9999999;
  transition: 0.3s;
}

.mode_switcher.my_switcher:hover {
  right: 0;
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .mode_switcher.my_switcher {
    top: 300px;
  }
}
.mode_switcher.my_switcher button {
  display: flex;
  font-size: 20px;
  color: var(--whiteColor);
  background: var(--primaryColor);
  padding: 10px;
  line-height: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 90px;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.15);
  border: none;
}

.mode_switcher.my_switcher button svg {
  width: 20px;
  margin-right: 10px;
}

.mode_switcher.my_switcher button span {
  font-size: 16px;
}

.light__mode {
  display: none;
}

.headerarea--3 {
  box-shadow: rgba(95, 45, 237, 0.08) 0px 6px 14px;
}
.headerarea--3 .headerarea__main__wrapper {
  background-color: transparent;
}
.headerarea__transparent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}
.headerarea__transparent--2 {
  top: 40px;
}
.headerarea__component__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerarea__main__wrapper {
  padding: 0 20px;
  border-radius: var(--borderRadius);
}
.headerarea--1 .headerarea__main__wrapper {
  background-color: rgba(255, 240, 250, 0.05);
}
.headerarea__main__wrapper--2 {
  background: var(--gradientColor);
}
.headerarea__main__wrapper--3 {
  padding: 0;
}
.headerarea__main__wrapper--4 {
  border-bottom: 1px solid var(--borderColor3);
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 0;
}
@media (max-width: 767px) {
  .headerarea__main__wrapper--4 {
    padding-left: 0;
    padding-right: 0;
  }
}
.headerarea__icon ul {
  display: flex;
  align-items: center;
}
.headerarea__icon ul li {
  margin-right: 9px;
}
@media (max-width: 767px) {
  .headerarea__icon ul li {
    display: none;
  }
}
@media (max-width: 767px) {
  .headerarea__icon ul li.headerarea__icon__offcanvas {
    display: block;
  }
}
.headerarea__icon ul li:last-child {
  margin: 0;
}
.headerarea__icon ul li a,
.headerarea__icon ul li button {
  height: 52px;
  width: 52px;
  line-height: 52px;
  border: 1px solid var(--borderColor3);
  border-radius: 100%;
  display: inline-block;
  text-align: center;
}
.headerarea__icon ul li a svg rect,
.headerarea__icon ul li button svg rect {
  transition: var(--transition);
}
.headerarea__icon ul li a:hover,
.headerarea__icon ul li button:hover {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.headerarea__icon ul li a:hover svg rect,
.headerarea__icon ul li button:hover svg rect {
  fill: var(--whiteColor);
}
@media (max-width: 767px) {
  .headerarea__icon.headerarea__offcavas__icon li {
    display: block;
  }
}
.headerarea__logo {
  display: flex;
  height: 100%;
  align-items: center;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__logo img {
    max-width: 150px;
  }
}
.headerarea__main__menu ul li a {
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  padding: 33px 18px 37px;
  display: block;
  color: var(--whiteColor);
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .headerarea__main__menu ul li a {
    padding: 22px 10px 31px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__main__menu ul li a {
    padding: 20px 8px;
    font-size: 12px;
  }
}
.headerarea__main__menu ul li:hover > a {
  color: var(--primaryColor);
}
.headerarea__main__menu ul li .headerarea__mega__menu__img a {
  padding: 0;
}
.headerarea__main__menu ul li .headerarea__mega__menu__title a {
  padding: 20px;
}
.headerarea__main__menu .headerarea__submenu li {
  display: block;
  border-left: 2px solid transparent;
}
.headerarea__main__menu .headerarea__submenu li:hover {
  border-left: 2px solid var(--primaryColor);
}
.headerarea__main__menu .headerarea__submenu li a {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerarea__common__dropdown {
  position: relative;
}
.offcanvas__menu {
  padding: 0;
  margin: 0;
}

.offcanvas__menu_ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.offcanvas__menu_item {
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}

.offcanvas__menu_item_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-toggler {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.has-children .dropdown-toggler {
  margin-left: 10px;
}

.offcanvas__submenu {
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding-left: 20px;
  max-height: 0;
}

.offcanvas__submenu_item {
  padding: 5px 0;
}

.offcanvas__submenu.open {
  max-height: 500px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
}

.offcanvas__submenu_item a {
  color: #555;
  text-decoration: none;
  transition: color 0.2s ease;
}

.offcanvas__menu_item_link a:hover {
  color: #000;
}

.headerarea__common__dropdown:hover .headerarea__submenu {
  top: 100%;
  visibility: visible;
  width: 300px;
  overflow-x: hidden;
  left: 0px;
  opacity: 1;
  clip: rect(0px, 100vw, 200vh, -30px);
  transition:
    clip 0.6s linear,
    opacity 0.4s linear;
}
.headerarea__has__dropdown {
  position: relative;
}
.headerarea__submenu {
  position: absolute;
  clip: rect(0px, 200vw, 0, 0px);
  transition:
    opacity 0.4s linear,
    clip 0.6s linear,
    visibility 0s 0.4s;
  transform: translateZ(0);
  background-color: rgb(17 24 39 / 95%);
  min-width: 200px;
  transition: 0.3s;
  top: 110%;
  left: 0;
  padding: 14px 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  box-shadow: 0 0 50px 10px rgba(15, 157, 193, 0.1);
  border-bottom-right-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
  text-align: left;
}
.headerarea__mega__menu__wrapper {
  width: 100%;
  max-height: 610px;
  overflow: auto;
  padding: 12px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__mega__menu__wrapper {
    max-height: 500px;
  }
}
.headerarea__mega__menu__grid__5 {
  padding: 15px 30px !important;
}
.headerarea__mega__menu__grid__5 .headerarea__mega__menu__single__wrap {
  width: 20%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__mega__menu__grid__5 .headerarea__mega__menu__single__wrap {
    width: 25%;
  }
}
.headerarea__tab__button__wrap {
  margin-bottom: 15px;
}
.headerarea__tab__button__wrap li {
  width: 50%;
}
.headerarea__tab__button__wrap li button {
  background: var(--whiteColor);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 12px 0;
  font-size: 18px;
  border: none;
  margin-right: 11px;
  font-weight: 600;
  color: var(--blackColor);
  width: 100%;
  border-radius: var(--borderRadius);
  position: relative;
  transition: var(--transition);
}
.headerarea__tab__button__wrap li button:hover,
.headerarea__tab__button__wrap li button.active {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  color: var(--primaryColor);
}
.headerarea__mega__menu__thumb {
  position: relative;
  box-shadow: 0px 0 5px 5px rgba(0, 0, 0, 0.05);
  margin: 15px 0;
  transition: var(--transition);
  overflow: hidden;
}
.headerarea__mega__menu__thumb:hover {
  box-shadow: 0px 0 5px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}
.headerarea__mega__menu__thumb img {
  width: 100%;
  border-radius: var(--borderRadius);
}
.headerarea__mega__menu__thumb a {
  padding: 0 !important;
}
.headerarea__mega__menu__thumb__title {
  position: absolute;
  left: 2px;
  right: 2px;
  bottom: -20px;
  color: var(--whiteColor);
  font-size: 13px;
  background: var(--secondaryColor);
  padding: 8px;
  line-height: 1;
  border-radius: var(--borderRadius);
  transition: var(--transition);
  opacity: 0;
  text-align: center;
}
.headerarea__mega__menu__thumb__title.title__onepage {
  background: var(--primaryColor);
  bottom: 10px;
}
.headerarea__mega__menu__thumb:hover .headerarea__mega__menu__thumb__title {
  bottom: 2px;
  opacity: 1;
}
.headerarea__mega__menu__thumb:hover
  .headerarea__mega__menu__thumb__title.title__onepage {
  bottom: 35px;
}
.headerarea__mega__menu__thumb__label {
  position: absolute;
  background: var(--primaryColor);
  font-size: 12px;
  padding: 5px 15px;
  line-height: 1;
  color: var(--whiteColor);
  border-radius: var(--borderRadius);
  top: 5px;
  left: 5px;
}
.headerarea__menu__special__label {
  position: relative;
  background: var(--gradientColor);
  font-size: 12px;
  padding: 5px 15px;
  line-height: 1;
  margin-left: 5px;
  color: var(--whiteColor);
  border-radius: var(--borderRadius);
}
.headerarea__mega__menu__img img {
  width: 100%;
}
.headerarea__mega__menu__title {
  border-bottom: 1px solid var(--borderColor);
}
.headerarea__submenu--five--wrap li {
  position: relative;
}
.headerarea__submenu--five--wrap li:hover .headerarea__submenu--five {
  visibility: visible;
  opacity: 1;
}
.headerarea__submenu--five {
  position: absolute;
  right: 0;
  left: 100%;
  min-width: 200px;
  top: 0;
  padding: 14px 0;
  z-index: 999;
  box-shadow: 0 0 50px 10px rgba(15, 157, 193, 0.1);
  border-bottom-right-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  background: var(--blackColor);
}
.headerarea__cart__border__dot {
  border-radius: 8px;
  background: var(--gradientColor);
  width: 8px;
  height: 8px;
  position: absolute;
  top: 6px;
}
.headerarea__right {
  display: flex;
  align-items: center;
}
.headerarea__button {
  margin-left: 25px;
}
.no-product-minicart {
  font-size: 16px;
  font-weight: 600;
  color: darkgrey;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__button {
    margin-left: 15px;
  }
}
.headerarea__cart__wraper {
  position: relative;
}
.headerarea__cart__wraper:hover .headerarea__cart__dropdown__wrapper {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.headerarea__cart__dropdown__wrapper {
  position: absolute;
  width: 350px;
  max-height: 700px;
  padding: 20px;
  border-bottom: 3px solid var(--borderColor);
  background-color: var(--whiteColor);
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.15);
  top: 70px;
  left: -117px;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: var(--transition);
  border-radius: var(--borderRadius);
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__cart__dropdown__wrapper {
    left: auto;
    right: 0;
  }
}
@media (max-width: 767px) {
  .headerarea__cart__dropdown__wrapper {
    left: auto;
    right: 0;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .headerarea__cart__dropdown__wrapper {
    right: -90px;
    width: 300px;
  }
}
.headerarea__cart__dropdown__inner {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 30px;
  max-height: 327px;
  height: auto;
  overflow: auto;
}
.headerarea__cart__single__dropdown {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}
@media (min-width: 320px) and (max-width: 575px) {
  .headerarea__cart__single__dropdown {
    margin-bottom: 10px;
  }
}
.headerarea__cart__single__dropdown__img {
  width: 35%;
}
.headerarea__cart__single__dropdown__img img {
  width: 100%;
  border-radius: var(--borderRadius2);
}
.headerarea__cart__single__dropdown__content {
  text-align: left;
  width: 65%;
  margin-left: 15px;
}
.headerarea__cart__single__dropdown__content a {
  line-height: 20px;
  color: var(--blackColor);
  font-size: 15px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .headerarea__cart__single__dropdown__content a {
    font-size: 12px;
  }
}
.headerarea__cart__single__dropdown__content a:hover {
  color: var(--primaryColor);
}
.headerarea__cart__single__dropdown__content p .price {
  color: var(--primaryColor);
}
.headerarea__cart__dropdown__price span {
  color: var(--primaryColor);
  float: right;
  font-weight: 700;
}
.headerarea__cart__single__dropdown__close {
  position: absolute;
  right: 0;
  top: -10px;
}
.headerarea__cart__dropdown__price {
  text-align: left;
  font-size: 17px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .headerarea__cart__dropdown__button {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
.headerarea__cart__dropdown__button .default__button {
  padding: 18px 40px;
}
.headerarea__bar__wrapper {
  background: var(--whiteColor);
  border: none;
  margin-left: 40px;
}
.headerarea__bar span {
  background: var(--blackColor);
  height: 2px;
  width: 24px;
  display: block;
  margin-top: 5px;
  margin-bottom: 6px;
  padding-left: 9px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.headerarea__offcavas__close__button {
  padding: 30px 100px 20px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__offcavas__close__button {
    padding: 30px 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__offcavas__close__button {
    padding: 30px 50px;
  }
}
@media (max-width: 767px) {
  .headerarea__offcavas__close__button {
    padding: 20px 50px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .headerarea__offcavas__close__button {
    padding: 20px 30px;
  }
}
.headerarea__offcavas__body {
  padding: 20px 100px 50px 100px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__offcavas__body {
    padding: 0 50px 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__offcavas__body {
    padding: 0 50px 20px;
  }
}
@media (max-width: 767px) {
  .headerarea__offcavas__body {
    padding: 0 50px 20px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .headerarea__offcavas__body {
    padding: 0 30px 20px;
  }
}
.headerarea__offcavas__wrapper {
  width: 60vw !important;
  min-width: 300px;
  transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 999999999;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__offcavas__wrapper {
    width: 80vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__offcavas__wrapper {
    width: 80vw !important;
  }
}
@media (max-width: 767px) {
  .headerarea__offcavas__wrapper {
    width: 95vw !important;
  }
}
.headerarea__offcavas__form {
  margin-bottom: 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__offcavas__form {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__offcavas__form {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .headerarea__offcavas__form {
    margin-bottom: 30px;
  }
}
.headerarea__offcavas__form .form-group {
  position: relative;
}
.headerarea__offcavas__form .form-group input {
  font-size: 30px;
  height: auto;
  border: none;
  border-bottom: 2px solid var(--borderColor);
  border-radius: 0;
  padding: 5px 0;
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__offcavas__form .form-group input {
    font-size: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__offcavas__form .form-group input {
    font-size: 25px;
  }
}
@media (max-width: 767px) {
  .headerarea__offcavas__form .form-group input {
    font-size: 25px;
  }
}
.headerarea__offcavas__form .form-group button {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 0;
}
.headerarea__offcavas__form .form-group button i {
  font-size: 24px;
}
.headerarea__offcavas__menu .active {
  color: var(--primaryColor);
}
.headerarea__offcavas__menu li {
  margin: 20px 0;
  display: block;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__offcavas__menu li {
    margin: 15px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__offcavas__menu li {
    margin: 15px 0;
  }
}
@media (max-width: 767px) {
  .headerarea__offcavas__menu li {
    margin: 10px 0;
  }
  .headerarea__offcavas__menu li:last-child {
    margin-bottom: 20px;
  }
}
.headerarea__offcavas__menu li:first-child {
  margin-top: 0;
}
.headerarea__offcavas__menu li a {
  font-size: 26px;
  font-weight: 500;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__offcavas__menu li a {
    font-size: 22px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea__offcavas__menu li a {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .headerarea__offcavas__menu li a {
    font-size: 20px;
  }
}
.headerarea__offcavas__address {
  margin-bottom: 40px;
}
.headerarea__offcavas__address span {
  font-size: 18px;
  line-height: 25px;
  color: var(--blackColor);
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}
.headerarea__offcavas__address a {
  color: var(--blackColor);
  display: block;
  margin-bottom: 10px;
}
.headerarea__offcavas__address a:hover {
  color: var(--primaryColor);
}
.headerarea__offcavas__address a i {
  margin-right: 20px;
  font-weight: 900;
}
.headerarea__offcavas__contact__inner h5 {
  font-size: 18px;
  line-height: 1;
  color: var(--blackColor);
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .headerarea__offcavas__contact {
    padding-top: 20px;
    border-top: 1px solid var(--borderColor);
  }
}

.headerarea__offcavas__menu {
  max-height: 650px;
  overflow: auto;
}

.header__topbar {
  padding: 8px;
}
.header__topbar__left {
  display: flex;
  justify-content: flex-end;
}
.header__topbar__left ul li {
  margin-right: 60px;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
}
.header__topbar__left ul li:last-child {
  margin-right: 0;
}
.header__topbar__left ul li svg {
  margin-right: 15px;
}
.header__topbar__right {
  display: flex;
  align-items: center;
  padding-left: 40px;
}
.header__topbar__right svg {
  margin-right: 15px;
}
.header__topbar__right p {
  margin: 0;
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
}

.header__animate {
  background: var(--gradientColor);
  overflow: hidden;
}
.header__animate__wraper {
  display: flex;
  height: 50px;
  white-space: nowrap;
  position: relative;
  will-change: transform;
  animation: marquee 50s linear 0s infinite normal;
  animation-play-state: running;
}
.header__animate__wraper:hover {
  animation-play-state: paused;
}
.header__animate__item {
  margin-right: 40px;
  display: flex;
  align-items: center;
}
.header__animate__item h3 {
  font-size: 13px;
  letter-spacing: 1.95px;
  color: var(--whiteColor);
  margin: 0;
}
.header__animate__item img {
  margin-right: 40px;
}

.animate__content {
  background: var(--borderColor);
  overflow: hidden;
}
.animate__content__wraper {
  display: flex;
  padding: 25px 0;
  white-space: nowrap;
  position: relative;
  will-change: transform;
  animation: marquee 50s linear 0s infinite normal;
  animation-play-state: running;
}
.animate__content__wraper:hover {
  animation-play-state: paused;
}
.animate__content__item {
  margin-right: 40px;
  display: flex;
  align-items: center;
}
.animate__content__item h3 {
  font-size: 60px;
  letter-spacing: 1.95px;
  color: var(--blackColor);
  margin: 0;
}
@media (max-width: 767px) {
  .animate__content__item h3 {
    font-size: 30px;
  }
}
.animate__content__item img {
  margin-right: 40px;
}
.animate__content__item--2 h3 {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-color: var(--secondaryColor);
  -webkit-text-stroke-width: 1px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headerarea--2.headerarea__transparent {
    position: static;
  }
}
@media (max-width: 767px) {
  .headerarea--2.headerarea__transparent {
    position: static;
  }
}

.header__sticky.sticky {
  position: fixed;
  animation: 0.95s ease-in-out 0s 1 normal none running fadeInDown;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  box-shadow: rgba(95, 45, 237, 0.15) 0px 6px 34px;
  background: rgba(0, 0, 0, 0.9);
  top: 0px;
  width: 100%;
  z-index: 99999;
}

.headerarea--2.header__sticky.sticky {
  background: var(--gradientColor);
  box-shadow: none;
}
.headerarea--2.header__sticky.sticky .header__topbar {
  display: none;
}
.headerarea--2.header__sticky.sticky .headerarea__main__wrapper--2 {
  background: none;
}

.headerarea--4.header__sticky.sticky {
  background: var(--whiteColor);
}
.headerarea--4.header__sticky.sticky .headerarea__main__wrapper--4 {
  border: none;
}
@media (max-width: 767px) {
  .headerarea--4 .container {
    width: 100%;
    max-width: 100%;
  }
}

.bg__black .headerarea--4.header__sticky.sticky {
  background: var(--blackColor);
}

@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea--2 .default__button {
    padding: 15px 20px;
  }
  .headerarea .default__button {
    padding: 10px 20px;
  }
}
.offcanvas__header--menu__open {
  line-height: 1;
  display: none;
}

@media only screen and (max-width: 991px) {
  .offcanvas__header--menu__open {
    display: block;
  }
}
.offcanvas__header--menu__open--svg {
  width: 32px;
}

.offcanvas__header--menu__open--btn > * {
  pointer-events: none;
}

.offcanvas__header {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background-color: var(--body-background-color);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 480px) {
  .offcanvas__header {
    max-width: 320px;
  }
}
.offcanvas__header.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.offcanvas__header.open ~ .offcanvas-overlay {
  visibility: visible;
  opacity: 0.75;
}

.offcanvas-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  background-color: var(--bg-black-color);
}

.offcanvas__inner {
  position: relative;
  height: 100%;
  padding-bottom: 5rem;
  background: var(--whiteColor);
}
.logo-image1 {
  display: none;
}

.offcanvas__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 15px;
}

.offcanvas__logo img {
  max-width: 130px;
}

.offcanvas__close--btn {
  position: relative;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  width: 20px;
  height: 20px;
  padding: 0;
  text-indent: -9999px;
  border: none;
  background-color: transparent;
}

.offcanvas__close--btn::after,
.offcanvas__close--btn::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -2px;
  content: "";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: var(--blackColor);
  transition: var(--transition);
}

.offcanvas__close--btn::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.offcanvas__close--btn:hover::after,
.offcanvas__close--btn:hover::before {
  background-color: var(--primaryColor);
}

.offcanvas__menu {
  overflow-y: auto;
  height: 100%;
}

.offcanvas__menu_ul {
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 300px;
}

.offcanvas__menu_li {
  position: relative;
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}

.offcanvas__menu_li:first-child {
  border-top: 1px solid var(--borderColor);
}

.offcanvas__menu_item {
  line-height: 1;
  display: block;
  padding: 15px 20px;
  color: var(--blackColor);
  font-size: 16px;
}
.offcanvas__menu_item:hover {
  color: var(--blackColor);
}

.offcanvas__sub_menu {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.offcanvas__sub_menu_li {
  position: relative;
  border-top: 1px solid var(--borderColor);
  width: 100%;
}

.offcanvas__sub_menu_item {
  line-height: 1;
  display: block;
  padding: 15px 0 15px 30px;
  color: var(--blackColor);
  font-size: 14px;
}
.offcanvas__sub_menu_item:hover {
  color: var(--blackColor);
}

.offcanvas__sub_menu_item ~ .offcanvas__sub_menu .offcanvas__sub_menu_item {
  padding-left: 40px;
}

.offcanvas__sub_menu_toggle {
  font-size: 20px;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.offcanvas__sub_menu_toggle::after,
.offcanvas__sub_menu_toggle::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  content: "";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--blackColor);
}

.offcanvas__sub_menu_toggle:not(.active)::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.offcanvas__sub_menu_toggle:hover::after,
.offcanvas__sub_menu_toggle:hover::before {
  background-color: var(--primaryColor);
}

.offcanvas__account--items {
  margin-top: 2rem;
  padding: 0 1.5rem;
}

.offcanvas__account--items__btn {
  color: var(--blackColor);
  gap: 0.5rem;
}

.offcanvas__sticky--toolbar {
  position: fixed;
  bottom: 0;
  background: var(--body-background-color);
  left: 0;
  right: 0;
  z-index: 99;
  -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
  padding: 14px 20px;
  display: none;
}

@media only screen and (min-width: 500px) {
  .offcanvas__sticky--toolbar {
    padding: 14px 40px;
  }
}
@media only screen and (min-width: 768px) {
  .offcanvas__sticky--toolbar {
    padding: 14px 60px;
  }
}
@media only screen and (max-width: 991px) {
  .offcanvas__sticky--toolbar {
    display: block;
  }
}
.offcanvas__sticky--toolbar__btn {
  position: relative;
  text-align: center;
}

.offcanvas__sticky--toolbar__btn:hover .offcanvas__sticky--toolbar__icon {
  background: var(--primaryColor);
  color: var(--text-white-color);
}

.offcanvas__sticky--toolbar__btn > * {
  pointer-events: none;
}

.offcanvas__sticky--toolbar__btn.minicart__open--btn {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.offcanvas__sticky--toolbar__icon {
  width: 3.2rem;
  height: 3.2rem;
  text-align: center;
  background: var(--secondary-color);
  border-radius: 50%;
  color: var(--text-white-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.offcanvas__sticky--toolbar__label {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 6px;
}

.offcanvas__sticky--toolbar .items__count {
  left: 2.3rem;
  top: -9px;
}

.mob_menu_wrapper {
  display: none;
}

@media only screen and (max-width: 991px) {
  .desktop__menu__wrapper {
    display: none;
  }
  .mob_menu_wrapper {
    display: block;
  }
}
.mobile__component__right .headerarea__button {
  margin-left: 10px;
}

.mobile__component__right .default__button {
  padding: 10px 20px;
}

.mobile__log--img {
  max-width: 150px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .mobile__log--img {
    max-width: 100px;
  }
}

.mob_menu_wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(255, 240, 250, 0.05);
}

.headerarea--2 .mob_menu_wrapper {
  background: var(--gradientColor);
}

.offcanvas__header--menu__open--svg {
  color: var(--whiteColor);
}

.offcanvas__header--menu__open--btn:hover .offcanvas__header--menu__open--svg {
  color: var(--primaryColor);
}

.herobanner {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background: linear-gradient(90deg, #012a3e 0%, #5a3a06 100%);
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__display__none {
    display: none;
  }
}
@media (max-width: 767px) {
  .herobanner__display__none {
    display: none;
  }
}
.herobanner__title {
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__title {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__title {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .herobanner__title {
    margin-bottom: 15px;
  }
}
.herobanner__title h1 {
  color: var(--whiteColor);
  font-size: 91px;
  font-style: normal;
  font-weight: 700;
  line-height: 98px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobanner__title h1 {
    font-size: 62px;
    line-height: 70px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__title h1 {
    font-size: 40px;
    line-height: 55px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__title h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .herobanner__title h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__title h1 {
    font-size: 26px;
    line-height: 36px;
  }
}
.herobanner__title--mid h1 {
  font-size: 64px;
  line-height: 80px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__title--mid h1 {
    font-size: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__title--mid h1 {
    font-size: 40px;
    line-height: 55px;
  }
}
@media (max-width: 767px) {
  .herobanner__title--mid h1 {
    font-size: 26px;
    line-height: 40px;
  }
}
.herobanner__title--2 h1 {
  color: var(--blackColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__title--2 h1 {
    font-size: 30px;
    line-height: 40px;
  }
}
.herobanner__text p {
  color: var(--whiteColor);
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  max-width: 80%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__text p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__text p {
    font-size: 16px;
    line-height: 30px;
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  .herobanner__text p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__text p {
    font-size: 16px;
    line-height: 26px;
    max-width: 100%;
  }
}
.herobanner__button {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__button {
    margin-top: 20px;
    flex-wrap: wrap;
  }
}
.herobanner__text--2 p {
  color: var(--blackColor);
}
.herobanner__img--position img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobanner__img--position img {
    max-width: 600px;
    bottom: auto;
    top: 0;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__img--position img {
    max-width: 500px;
    bottom: auto;
    top: 0;
    right: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__img--position img {
    max-width: 60%;
    left: 0;
    margin: auto;
  }
}
@media (max-width: 767px) {
  .herobanner__img--position img {
    max-width: 60%;
    left: 0;
    margin: auto;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__img--position img {
    max-width: 90%;
  }
}
.herobanner__vector {
  position: absolute;
  right: 0;
  bottom: 0;
}
.herobanner__vector.hero__icon__1 {
  left: 120px;
  bottom: 85px;
  animation: cir-anim 6s linear 0s infinite alternate;
}
.herobanner__vector.hero__icon__2 {
  right: 42%;
  bottom: 15%;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobanner__vector.hero__icon__2 {
    right: 43%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__vector.hero__icon__2 {
    right: 25%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__vector.hero__icon__2 {
    display: none;
  }
}
@media (max-width: 767px) {
  .herobanner__vector.hero__icon__2 {
    display: none;
  }
}
.herobanner__vector.hero__icon__3 {
  right: 160px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobanner__vector.hero__icon__3 {
    right: 5%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__vector.hero__icon__3 {
    right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__vector.hero__icon__3 {
    right: 50px;
  }
}
@media (max-width: 767px) {
  .herobanner__vector.hero__icon__3 {
    right: 30px;
    width: 90%;
  }
}
.herobanner__vector.hero__icon__4 {
  top: 22%;
  right: 44%;
  animation: 3s linear 0s infinite normal none running move5;
}
.herobanner__icon--2 {
  top: 50%;
  position: absolute;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 999;
}
.herobanner__img--2 {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 50%;
}
.herobanner__img--2 img {
  width: 100%;
}
.herobanner__small__text span {
  font-size: 15px;
  font-weight: 700;
  color: var(--primaryColor);
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  display: inline-block;
}
.herobanner__small__text span::before {
  position: absolute;
  content: "";
  height: 4px;
  width: 30px;
  background: var(--primaryColor);
  top: 47%;
  left: 0;
}

.slider__bg__position .herobanner__single {
  background-position: center center !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__img.sp_top_90 {
    padding-top: 30px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .herobanner__img.sp_top_90 {
    padding-top: 30px;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__img img.img-fluid {
    max-width: 70%;
  }
}
@media (max-width: 767px) {
  .herobanner__img img.img-fluid {
    max-width: 70%;
  }
}

@media (max-width: 767px) {
  .herobanner--2.d-flex {
    flex-wrap: wrap;
  }
  .herobanner--2.d-flex .herobanner__img--2 {
    position: static;
    max-width: 100%;
  }
  .herobanner--2.d-flex.herobanner__with__transparent__header
    .herobanner__content__wrapper {
    padding: 50px 0 60px;
  }
}
.herobanner__with__transparent__header .herobanner__content__wrapper {
  padding-top: 80px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__with__transparent__header .herobanner__content__wrapper {
    padding-top: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__with__transparent__header .herobanner__content__wrapper {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .herobanner__with__transparent__header .herobanner__content__wrapper {
    padding-top: 30px;
  }
}

.herobanner .bastun__brand__badge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 99;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner .bastun__brand__badge {
    top: auto;
    bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner .bastun__brand__badge {
    top: auto;
    bottom: 0;
  }
}
@media (max-width: 767px) {
  .herobanner .bastun__brand__badge {
    top: 37%;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner .bastun__brand__badge {
    top: 60%;
  }
}

.herobanner.herobanner--3 .bastun__brand__badge {
  top: auto;
  bottom: inherit;
}

.ht__white__bg {
  background: var(--whiteColor);
  color: var(--blackColor);
  padding: 0 5px;
}

.ht__gradient__bg {
  background: var(--gradientColor);
  padding: 0 10px;
}

.herobanner__arrow__1.slider__controls__arrows .arrow-btn {
  right: 49%;
  left: auto;
  bottom: 40px;
  top: auto;
  opacity: 0;
}

.herobanner:hover .herobanner__arrow__1.slider__controls__arrows .arrow-btn {
  opacity: 1;
  right: 50%;
}
@media (max-width: 767px) {
  .herobanner:hover .herobanner__arrow__1.slider__controls__arrows .arrow-btn {
    right: 45%;
  }
}

.herobanner__arrow__1.slider__controls__arrows .arrow-btn.swiper-button-next {
  bottom: 120px;
  right: 51%;
}

.herobanner:hover
  .herobanner__arrow__1.slider__controls__arrows
  .arrow-btn.swiper-button-next {
  right: 50%;
}
@media (max-width: 767px) {
  .herobanner:hover
    .herobanner__arrow__1.slider__controls__arrows
    .arrow-btn.swiper-button-next {
    right: 45%;
    bottom: 100px;
  }
}

.herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
  .arrow-btn {
  right: 150px;
  left: auto;
  bottom: 50%;
  top: auto;
  opacity: 0;
}
@media (max-width: 767px) {
  .herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
    .arrow-btn {
    right: 20px;
  }
}

.herobanner:hover
  .herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
  .arrow-btn {
  opacity: 1;
  right: 140px;
}
@media (max-width: 767px) {
  .herobanner:hover
    .herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
    .arrow-btn {
    right: 30px;
  }
}

.herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
  .arrow-btn.swiper-button-next {
  right: 130px;
  bottom: 40%;
}
@media (max-width: 767px) {
  .herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
    .arrow-btn.swiper-button-next {
    right: 20px;
    bottom: 35%;
  }
}

.herobanner:hover
  .herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
  .arrow-btn.swiper-button-next {
  right: 140px;
}
@media (max-width: 767px) {
  .herobanner:hover
    .herobanner__arrow__1.herobanner__arrow__1--2.slider__controls__arrows
    .arrow-btn.swiper-button-next {
    right: 30px;
    bottom: 35%;
  }
}

.hero__simple__slider__arrow.slider__controls__arrows .arrow-btn {
  border-color: var(--blackColor);
}

.hero__simple__slider__arrow.slider__controls__arrows
  .arrow-btn.swiper-button-next {
  right: 10px;
}

.hero__simple__slider__arrow.slider__controls__arrows
  .arrow-btn.swiper-button-prev {
  left: 10px;
}

.hero__simple__slider__arrow.slider__controls__pagination .swiper-pagination {
  bottom: 20px;
}

.bg__image {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}

.herobanner__content__wrapper--2 .herobanner__title {
  margin-bottom: 20px;
}
.herobanner__content__wrapper--2 .herobanner__title h1 {
  font-size: 60px;
  line-height: 70px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__content__wrapper--2 .herobanner__title h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .herobanner__content__wrapper--2 .herobanner__title h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__content__wrapper--2 .herobanner__title h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .herobanner__content__wrapper--3 .herobanner__title h1 {
    font-size: 80px;
    line-height: 100px;
  }
}

.herobanner__button .video__button {
  position: static;
  transform: none;
  margin-left: 20px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__button .video__button {
    margin-top: 10px;
    margin-left: 0;
  }
}
.herobanner__button .video__button a {
  height: 70px;
  width: 70px;
  line-height: 70px;
  position: relative;
  border: 1px solid var(--borderColor);
}
.herobanner__button .video__button a::before {
  animation: 2s ease 0s infinite normal none running pulse_2;
  width: 70px;
  height: 70px;
  left: -1px;
  bottom: -1px;
}
.herobanner__button .video__button span {
  margin-left: 5px;
}
.herobanner__button .video__button.video__button--white span {
  color: var(--whiteColor);
}

.herobanner__front__img img {
  width: 100%;
}

.herobanner.herobanner__margin {
  margin: 50px 100px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 50px;
  border-radius: 50px;
  background: var(--borderColor);
}
@media (min-width: 1200px) and (max-width: 1365px) {
  .herobanner.herobanner__margin {
    margin: 50px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner.herobanner__margin {
    margin: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner.herobanner__margin {
    margin: 50px;
  }
}
@media (max-width: 767px) {
  .herobanner.herobanner__margin {
    margin: 20px;
  }
}

.herobannerarea__price {
  font-size: 40px;
  font-weight: 700;
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .herobannerarea__price {
    font-size: 30px;
  }
}
.herobannerarea__price del {
  color: var(--contentColor);
  font-size: 30px;
  font-weight: normal;
  margin-left: 10px;
}
.herobannerarea__price span {
  font-size: 12px;
  background: var(--secondaryColor);
  color: var(--whiteColor);
  padding: 5px 10px;
  border-radius: 5px;
  line-height: 1;
  margin-left: 15px;
}

.herobanner__content__wrapper--portfolio {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 100%;
  text-align: center;
}
.herobanner__content__wrapper--portfolio .herobanner__button {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 9999;
}
.herobanner__content__wrapper--portfolio .herobanner__title h1 {
  font-size: 175px;
  line-height: 1.1;
  letter-spacing: 1.5px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobanner__content__wrapper--portfolio .herobanner__title h1 {
    font-size: 145px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__content__wrapper--portfolio .herobanner__title h1 {
    font-size: 120px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__content__wrapper--portfolio .herobanner__title h1 {
    font-size: 90px;
  }
}
@media (max-width: 767px) {
  .herobanner__content__wrapper--portfolio .herobanner__title h1 {
    font-size: 65px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__content__wrapper--portfolio .herobanner__title h1 {
    font-size: 35px;
  }
}
.herobanner__content__wrapper--portfolio .herobanner__title h1 .text__strock {
  display: block;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.5);
  -webkit-text-stroke-width: 2px;
}
.herobanner__content__wrapper--portfolio .herobanner__title {
  width: 100%;
}

.herobanner__img--portfolio {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobanner__img--portfolio img.img-fluid {
    width: 55%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobanner__img--portfolio img.img-fluid {
    width: 55%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__img--portfolio img.img-fluid {
    width: 80%;
    max-width: 80%;
  }
}
@media (max-width: 767px) {
  .herobanner__img--portfolio img.img-fluid {
    width: 80%;
    max-width: 80%;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__img--portfolio img.img-fluid {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .herobanner__single--portfolio .height__970 {
    height: 700px;
  }
}
@media (max-width: 767px) {
  .herobanner__single--portfolio .height__970 {
    height: 600px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner__single--portfolio .height__970 {
    height: 500px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .herobanner.herobanner__front__img__wrapper {
    height: auto;
  }
  .herobanner.herobanner__front__img__wrapper
    .herobanner__content__wrapper--2
    .herobanner__title {
    margin-bottom: 10px;
  }
  .herobanner.herobanner__front__img__wrapper
    .herobanner__content__wrapper--2
    .herobanner__title
    h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__button {
    margin-top: 10px;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__front__img {
    margin-top: 30px;
    text-align: center;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__front__img img {
    max-width: 80%;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__single {
    padding: 50px 0px;
  }
}
@media (max-width: 767px) {
  .herobanner.herobanner__front__img__wrapper {
    height: auto;
  }
  .herobanner.herobanner__front__img__wrapper
    .herobanner__content__wrapper--2
    .herobanner__title {
    margin-bottom: 10px;
  }
  .herobanner.herobanner__front__img__wrapper
    .herobanner__content__wrapper--2
    .herobanner__title
    h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__button {
    margin-top: 10px;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__front__img {
    margin-top: 30px;
    text-align: center;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__front__img img {
    max-width: 80%;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__single {
    padding: 50px 0px;
    position: relative;
    z-index: 999;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .herobanner.herobanner__front__img__wrapper
    .herobanner__content__wrapper--2
    .herobanner__title
    h1 {
    font-size: 24px;
    line-height: 35px;
  }
  .herobanner.herobanner__front__img__wrapper .herobanner__front__img img {
    max-width: 90%;
  }
  .herobanner.herobanner__front__img__wrapper
    .herobanner__button
    .video__button {
    margin-top: 0;
    margin-left: 6px;
  }
  .herobanner.herobanner__front__img__wrapper
    .herobanner__button
    .video__button
    .video__button__text {
    margin-left: 5px;
    font-size: 14px;
  }
  .herobanner.herobanner__front__img__wrapper .default__button {
    padding: 20px 10px;
  }
}
.service {
  position: relative;
  background-color: var(--whiteColor);
}
.service__single__wraper {
  padding: 32px 45px 40px;
  background: var(--whiteColor);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: var(--borderRadius);
  position: relative;
  overflow: hidden;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .service__single__wraper {
    padding: 25px 30px 37px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__single__wraper {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__single__wraper {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .service__single__wraper {
    margin-bottom: 10px;
    padding: 20px;
  }
}
.service__single__wraper:hover .service__heading h5 a {
  color: var(--whiteColor);
}
.service__single__wraper:hover .service__text p {
  color: var(--whiteColor);
}
.service__single__wraper:hover .service__icon a {
  background: var(--whiteColor);
}
.service__single__wraper:hover .service__img__bg img {
  opacity: 1;
  top: -60px;
}
.service__single__inner {
  position: relative;
  z-index: 99;
}
.service__img {
  margin-bottom: 25px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
}
.service__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.service__img img {
  max-width: 100%;
  object-fit: cover;
}
.service__img__bg img {
  position: absolute;
  top: -40px;
  left: -40px;
  z-index: 9;
  opacity: 0;
  transition: var(--transition);
}
.service__heading {
  margin-bottom: 20px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .service__heading {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__heading {
    margin-bottom: 7px;
  }
}
@media (max-width: 767px) {
  .service__heading {
    margin-bottom: 7px;
  }
}
.service__heading h5 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .service__heading h5 {
    font-size: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__heading h5 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .service__heading h5 {
    font-size: 16px;
  }
}
.service__heading h5 a {
  color: var(--blackColor);
}
.service__heading h5 a:hover {
  color: var(--primaryColor);
}
.service__text {
  margin-bottom: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.service__text p {
  color: var(--contentColor);
  font-weight: 400;
  line-height: 32px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .service__text p {
    font-size: 16px;
    line-height: 26px;
  }
}
.service__icon a {
  height: 62px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 58px;
  background: var(--whiteColor);
  border: 1px solid var(--borderColor);
  border-radius: 100%;
  color: white;
  text-align: center;
  font-size: 30px;
}
.service__single__wraper--2 {
  padding: 45px 40px 45px 45px;
  text-align: start;
  position: relative;
  margin-bottom: 35px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__single__wraper--2 {
    padding: 45px 20px 45px 20px;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__single__wraper--2 {
    padding: 45px 20px 45px 30px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .service__single__wraper--2 {
    padding: 45px 20px 45px 20px;
    margin-bottom: 10px;
  }
}
.service__single__wraper--2:hover .direction__btn--2::after {
  opacity: 0;
}
.service__single__wraper--2:hover .direction__btn--2 svg path {
  stroke: var(--whiteColor);
}
.service__single__wraper--2:hover .direction__btn--2 svg path {
  stroke: var(--blackColor);
}
.service__single__wraper--2:hover .service__bg__img svg stop {
  stop-color: var(--whiteColor);
}
.service__single__wraper--2 .service__img {
  position: relative;
}
.service__single__wraper--2 .service__bg__img {
  position: absolute;
  top: -5px;
  left: -7px;
  z-index: -11;
}
.service__single__wraper--2 .service__bg__img svg stop {
  transition: var(--transition);
}
.service__single__wraper--2 .service__text {
  margin: 0;
}
.service__single__wraper--2 .service__text p {
  margin-bottom: 0;
}
.service__icon--2 {
  position: absolute;
  top: 40px;
  right: 10px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__icon--2 {
    right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__icon--2 {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .service__icon--2 {
    right: 0px;
  }
}
.service__single__4 {
  padding: 40px 22px;
  background: var(--whiteColor);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: var(--transition);
  border-radius: 8px;
  -webkit-clip-path: polygon(
    0 0,
    84% 0,
    100% 10%,
    100% 80%,
    100% 100%,
    16% 100%,
    0 88%,
    0 0
  );
  clip-path: polygon(
    0 0,
    84% 0,
    100% 10%,
    100% 80%,
    100% 100%,
    16% 100%,
    0 88%,
    0 0
  );
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__single__4 {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__single__4 {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .service__single__4 {
    margin-bottom: 10px;
  }
}
.service__heading__4 {
  margin-bottom: 10px;
}
.service__heading__4 h3 {
  margin: 0;
}
.service__heading__4 h3 a {
  font-weight: 700;
  line-height: 30px;
  font-size: 20px;
  color: var(--blackColor);
}
.service__heading__4 h3 a:hover {
  color: var(--primaryColor);
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .service__heading__4 h3 a {
    line-height: 25px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .service__heading__4 h3 a {
    line-height: 25px;
    font-size: 18px;
  }
}
.service__img__4 {
  margin-bottom: 25px;
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.service__img__4 img {
  max-width: 100%;
}
.service__content__4 {
  margin-bottom: 22px;
}
.service__content__4 p {
  margin: 0;
}
.service__bitton__4 {
  margin-top: 60px;
}
.service__single__wraper__3 {
  padding: 10px 10px 22px 10px;
}
.service__single__wraper__3 .service__content {
  padding: 15px;
}

.service__line__animation {
  top: 31%;
  right: 144px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .service__line__animation {
    top: 25%;
    right: 68px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__line__animation {
    top: 10%;
    right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__line__animation {
    top: 12%;
    right: 20px;
  }
}
@media (max-width: 767px) {
  .service__line__animation {
    top: 180px;
    right: 30px;
  }
}

.service__single__wraper:hover .service__icon a.direction__btn--2 {
  background: none;
}

.service__single__wraper:hover .service__icon a.direction__btn--2:hover {
  background: var(--whiteColor);
}

.service__details__img {
  margin-bottom: 25px;
  width: 100%;
  height: 420px;
  overflow: hidden;
  border-radius: 12px;
}
.service__details__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.service__details__heading {
  margin-top: 20px;
}
.service__details__heading h4 {
  font-size: 40px;
  font-weight: 700;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__details__heading h4 {
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__details__heading h4 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .service__details__heading h4 {
    font-size: 28px;
  }
  .service__details__img {
    margin-bottom: 25px;
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 12px;
  }
}
.service__details__text p {
  margin: 0;
  margin-bottom: 25px;
}
.service__details__planning__inner {
  padding: 44px 38px 16px 44px;
  background: var(--blackColor);
  border-radius: var(--borderRadius);
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .service__details__planning__inner {
    padding: 25px 20px 16px 25px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__details__planning__inner {
    padding: 15px 10px 16px 21px;
  }
}
@media (max-width: 767px) {
  .service__details__planning__inner {
    padding: 15px 10px 16px 21px;
    margin-top: 20px;
  }
}
.service__details__planning__text p {
  margin: 0;
  line-height: 32px;
  margin-bottom: 12px;
  color: white;
}
.service__details__planning__heading h6 {
  font-size: 25px;
  font-weight: 700;
  color: white;
}
.service__details__planning {
  margin-top: 36px;
  margin-bottom: 30px;
}
.service__details__planning__img img {
  width: 100%;
}
.service__details__img img {
  width: 100%;
}
.service__details__button {
  margin-top: 35px;
}

.sidebar {
  margin-right: 34px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .sidebar {
    margin-right: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sidebar {
    margin-right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .sidebar {
    margin-right: 0px;
  }
}
.sidebar__search {
  position: relative;
}
.sidebar__common__input {
  padding: 15px 0 15px 21px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(10, 6, 36, 0.12);
  background: var(--whiteColor);
  margin-bottom: 11px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sidebar__common__input {
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar__common__input {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .sidebar__common__input {
    margin-bottom: 5px;
  }
}
.sidebar__search__button {
  position: absolute;
  right: 22px;
  top: 20%;
  background: none;
  border: none;
}
.sidebar__widget {
  margin-bottom: 60px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sidebar__widget {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar__widget {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .sidebar__widget {
    margin-bottom: 20px;
  }
}
.sidebar__title {
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sidebar__title {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar__title {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .sidebar__title {
    margin-bottom: 15px;
  }
}
.sidebar__title h5 {
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sidebar__title h5 {
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar__title h5 {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .sidebar__title h5 {
    font-size: 20px;
  }
}
.sidebar__list ul li {
  display: block;
  margin-bottom: 11px;
}
.sidebar__list ul li a {
  display: inline-block;
  position: relative;
}
.sidebar__list ul li a.active {
  color: var(--primaryColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sidebar__list ul li a {
    font-size: 14px;
  }
}
.sidebar__list ul li a i {
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 15px;
}
.sidebar__button {
  padding: 30px;
  background: var(--pinkcolor);
  border-radius: var(--borderRadius);
}
.sidebar__button a {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar__button a i {
  font-size: 23px;
}
.sidebar__button a.sidebar__button__1 {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.sidebar__post ul li {
  margin-bottom: 30px;
  display: block;
}
.sidebar__post ul li a {
  display: flex;
  align-items: center;
}
.sidebar__post__img {
  margin-right: 20px;
}
.sidebar__post__text h6 {
  margin-bottom: 5px;
  line-height: 24px;
}
.sidebar__post__text h6:hover {
  color: var(--blackColor);
}
.sidebar__post__text span {
  font-size: 15px;
  font-weight: 500;
}

.quick-search {
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  box-shadow: var(--boxShadow1);
  background: var(--whiteColor);
  padding: 10px;
  padding-bottom: 0;
  border-radius: 0 5px;
}
.quick-search.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.quick-search ul li {
  margin-bottom: 10px;
}
.quick-search .sidebar__post__img {
  margin-right: 8px;
  width: 80px !important;
}
.quick-search .sidebar__post__text h6 {
  margin-bottom: 0px;
  line-height: 0px;
  font-size: 13px;
  text-transform: capitalize;
}

.quick-search .sidebar__post__text span {
  font-size: 10px;
}
.sidebar__tag ul li {
  margin-right: 7px;
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar__tag ul li {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .sidebar__tag ul li {
    margin-bottom: 10px;
  }
}
.sidebar__tag ul li a {
  padding: 9px 25px;
  border: 1px solid var(--contentColor);
  display: inline-block;
  border-radius: var(--borderRadius);
  position: relative;
  z-index: 111;
}
.sidebar__tag ul li a span {
  position: relative;
  z-index: 99;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .sidebar__tag ul li a {
    padding: 9px 21px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .sidebar__tag ul li a {
    padding: 9px 15px;
    font-size: 14px;
  }
}
.sidebar__tag ul li a:hover {
  color: var(--whiteColor);
  border: 1px solid var(--whiteColor);
}
.sidebar__tag ul li a.active {
  color: var(--whiteColor);
  border: 1px solid var(--whiteColor);
}
.sidebar__tag ul li a.active::after {
  opacity: 1;
}

@media (min-width: 992px) and (max-width: 1365px) {
  .service.sp_bottom_330 {
    padding-bottom: 230px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service.sp_bottom_330 {
    padding-bottom: 200px;
  }
}
@media (max-width: 767px) {
  .service.sp_bottom_330 {
    padding-bottom: 150px;
  }
}

.video__img {
  position: relative;
  overflow: hidden;
}
.video__img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--blackColor);
  opacity: 0.7;
  border-radius: var(--borderRadius);
}
.video__img img {
  width: 100%;
  border-radius: var(--borderRadius);
}
.video__small__img {
  position: absolute;
  top: 44px;
  right: 38%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .video__small__img img {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .video__small__img img {
    display: none;
  }
}
@media (max-width: 767px) {
  .video__small__img img {
    display: none;
  }
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .video__small__img {
    top: 4px;
    right: 36%;
  }
}
.video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video__button a {
  height: 104px;
  width: 104px;
  line-height: 104px;
  border-radius: 100%;
  background: var(--whiteColor);
  display: inline-block;
  text-align: center;
}
@media (max-width: 767px) {
  .video__button a {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
.video__button a:hover {
  background: var(--primaryColor);
}
.video__button a:hover svg path {
  fill: var(--whiteColor);
}
.video__button a::before {
  position: absolute;
  content: "";
  width: 104px;
  height: 104px;
  border-radius: 50%;
  z-index: -1;
  animation: pulse 2s infinite;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .video__button a::before {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
.video__text h2 {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
  -webkit-text-stroke-width: 1px;
  font-size: 125px;
  font-weight: 700;
  position: absolute;
  margin: 0;
  line-height: 1;
  top: 50%;
  left: 150px;
  transform: translateY(-50%);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .video__text h2 {
    font-size: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .video__text h2 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .video__text h2 {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .video__text h2 {
    left: 30px;
  }
}
.video__text--2 h2 {
  right: 150px;
  left: auto;
}
@media (max-width: 767px) {
  .video__text--2 h2 {
    right: 30px;
  }
}
.video__text__spacing {
  padding-left: 180px;
}
@media (max-width: 767px) {
  .video__text__spacing {
    padding-left: 50px;
  }
}

.video__nmt {
  margin-top: -190px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .video__nmt {
    margin-top: -150px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .video__nmt {
    margin-top: -100px;
  }
}
@media (max-width: 767px) {
  .video__nmt {
    margin-top: -100px;
  }
}

.video__2 .video__border {
  border-top: 1px dashed var(--borderColor2);
}
.video__2 .video__small__img {
  top: 44px;
  right: 8%;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .video__2 .video__small__img {
    top: 7px;
    right: 5%;
  }
}
.video__2 .video__button {
  left: 79%;
}

/*--
    - Animation keyframes
-----------------------------------------*/
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse_2 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(254, 0, 148, 0.4);
    box-shadow: 0 0 0 0 rgba(254, 0, 148, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(254, 0, 148, 0);
    box-shadow: 0 0 0 45px rgba(254, 0, 148, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(254, 0, 148, 0);
    box-shadow: 0 0 0 0 rgba(254, 0, 148, 0);
  }
}

.faq__accordion .accordion-item {
  border: none;
}
.faq__accordion .accordion-item h2 button {
  margin-bottom: 20px;
  border: 1px solid var(--borderColor);
  font-weight: 700;
  border-radius: var(--borderRadius);
  background: transparent;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  color: var(--blackColor);
}

.accordion__img img {
  width: 100%;
}

.faq__accordion .accordion-item h2 button.accordion-button.collapsed {
  background: var(--borderColor);
  color: var(--blackColor);
}

.faq__accordion .accordion-body {
  border: 1px solid var(--borderColor);
  border-radius: 10px;
  margin-bottom: 20px;
}

.error__text__wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px dotted var(--borderColor);
  border-bottom: 1px dotted var(--borderColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 112px;
  padding-right: 112px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .error__text__wrapper {
    padding-top: 20px;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .error__text__wrapper {
    padding-top: 20px;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
  }
}
.error__text__wrapper h3 {
  font-size: 40px;
  font-weight: 700;
  width: 51%;
  margin: 0;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .error__text__wrapper h3 {
    width: 67%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .error__text__wrapper h3 {
    width: 71%;
    font-size: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .error__text__wrapper h3 {
    width: 71%;
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .error__text__wrapper h3 {
    width: 100%;
    font-size: 35px;
  }
}
.error__text__wrapper h3 span {
  font-style: italic;
  text-decoration-line: underline;
}

.about {
  position: relative;
}
.about__vision__wrapper--3 {
  margin-top: 60px;
}
.about__vision__wrapper--3 .about__misson h6 {
  color: var(--whiteColor);
}
.about__vision__wrapper--3 .about__misson h6::before {
  background: var(--whiteColor);
}
.about__vision__wrapper--3 .about__text__2 p {
  color: var(--whiteColor);
}
.about__vision__wrapper--3 .about__text__2 p a {
  color: var(--whiteColor);
  position: relative;
  text-decoration: underline;
}
.about__vision__wrapper--3 .about__number__inner {
  background: var(--primaryColor);
}
.about__img__wrapper {
  padding-left: 75px;
  padding-right: 45px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__img__wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__img__wrapper {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .about__img__wrapper {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 30px;
  }
}
.about__img__wrapper img {
  max-width: 100%;
}
.about__inner {
  padding-left: 35px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__inner {
    padding-left: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__inner {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .about__inner {
    padding-left: 0px;
  }
}
.about__misson {
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__misson {
    margin-bottom: 8px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__misson {
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  .about__misson {
    margin-bottom: 8px;
  }
}
.about__misson h6 {
  font-size: 26px;
  font-weight: 600;
  color: var(--blackColor);
  position: relative;
  display: inline-block;
  margin: 0;
}
@media (max-width: 767px) {
  .about__misson h6 {
    font-size: 21px;
  }
}
.about__misson h6:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--blackColor);
  bottom: 0;
  left: 0;
}
.about__mission__vission__wrapper {
  padding: 27px 25px 19px 25px;
  background: var(--pinkcolor);
  border-radius: 10px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .about__mission__vission__wrapper {
    margin-bottom: 10px;
  }
}
.about__mission__vission__wrapper span {
  color: var(--blackColor);
  font-weight: 600;
}
.about__mission__vission {
  margin-top: 33px;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .about__text__2 p {
    font-size: 15px;
  }
}
.about__text__2 p a {
  color: var(--blackColor);
  font-weight: 700;
  position: relative;
}
.about__text__2 p a:hover {
  color: var(--primaryColor);
}
.about__text__2 p a:hover:after {
  background: var(--primaryColor);
}
.about__text__2 p a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: var(--blackColor);
  transition: 0.3s;
}
.about__text__3 {
  margin-bottom: 35px;
}
.about__number__inner {
  background: var(--gradientColor);
  text-align: center;
  width: 159px;
  transition: var(--transition);
  border-radius: var(--borderRadius);
  height: 159px;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 15;
  justify-content: center;
  animation: 3s linear 0s infinite normal none running move5;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__number__inner {
    width: 133px;
    height: 130px;
    top: 20px;
  }
}
@media (max-width: 767px) {
  .about__number__inner {
    width: 133px;
    height: 130px;
  }
}
.about__number__inner span {
  font-size: 52px;
  font-weight: 700;
  line-height: 35px;
  color: var(--whiteColor);
  margin-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__number__inner span {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .about__number__inner span {
    font-size: 45px;
  }
}
.about__number__inner p {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 500;
  line-height: 35px;
  margin: 0;
}
.about__number__position {
  position: absolute;
  right: 112px;
  top: -236px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__number__position {
    right: 40px;
    top: -189px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__number__position {
    top: -125px;
  }
}
@media (max-width: 767px) {
  .about__number__position {
    right: 0;
    top: -117px;
  }
}
.about__number__icon {
  position: absolute;
  right: 16px;
  top: 9px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__number__icon {
    right: 6px;
    top: 3px;
  }
}
@media (max-width: 767px) {
  .about__number__icon {
    right: 6px;
    top: 3px;
  }
}
.about__number__icon a svg path {
  stroke: var(--whiteColor);
}
.about__number {
  position: relative;
}
.about__big__title h1 {
  color: var(--whiteColor);
  font-size: 93px;
  font-weight: 700;
  position: absolute;
  bottom: 30px;
  line-height: 1;
  left: 35px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.2);
  -webkit-text-fill-color: var(--whiteColor);
  animation: 3s linear 0s infinite normal none running move5;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__big__title h1 {
    font-size: 70px;
    bottom: 19px;
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__big__title h1 {
    left: 0px;
  }
}
@media (max-width: 767px) {
  .about__big__title h1 {
    left: 0px;
    font-size: 50px;
    bottom: 0;
  }
}
.about__vision__wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .about__vision__wrapper {
    margin-bottom: 30px;
    padding-top: 30px;
  }
}
.about__text__wrapper {
  margin-left: 40px;
}
@media (max-width: 767px) {
  .about__text__wrapper {
    margin-left: 15px;
  }
}
.about__inner--2 {
  padding-left: 45px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__inner--2 {
    padding-left: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__inner--2 {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .about__inner--2 {
    padding-left: 0px;
  }
}
.about__inner--2 .about__vision__wrapper {
  margin-bottom: 0;
  align-items: start;
}
.about__inner--2 .about__button {
  margin-top: 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__inner--2 .about__button {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__inner--2 .about__button {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .about__inner--2 .about__button {
    margin-top: 20px;
  }
}
.about__img__wrapper--2 {
  padding: 0;
}
.about__img__wrapper--3 {
  padding: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__img__3 {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .about__img__3 {
    margin-top: 20px;
  }
}
.about__img__3 img {
  width: 100%;
}
.about__white__bg .row {
  position: relative;
  z-index: 9;
}
.about__white__bg:after {
  position: absolute;
  height: 100%;
  content: "";
  width: 67%;
  left: 0;
  top: 0;
  z-index: 1;
  background: var(--whiteColor);
}
.about__white__bg.about__grident__bg:after {
  opacity: 0.4;
  background: var(
    --Gradient,
    linear-gradient(90deg, #00229e 0%, #6e1299 47.92%, #fe0094 100%)
  );
}

.bg__primary {
  background: var(--primaryColor);
}

.ssl__img__1 {
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .ssl__img__1 {
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ssl__img__1 {
    left: 0;
  }
}
@media (max-width: 767px) {
  .ssl__img__1 {
    left: 0;
    top: 10px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .ssl__img__1 {
    left: -35px;
    top: -30px;
  }
}

.ssl__img__2 {
  position: absolute;
  top: 0;
  right: 30%;
}
@media (max-width: 767px) {
  .ssl__img__2 {
    top: -24px;
    right: 24%;
  }
}

.ssl__img__3 {
  position: absolute;
  top: 60px;
  right: 30%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .ssl__img__3 {
    top: 16px;
  }
}
@media (max-width: 767px) {
  .ssl__img__3 {
    top: 0px;
    right: 25%;
  }
}

.portfolio__filter button {
  padding: 15px 25px;
  border: none;
  background: var(--pinkcolor);
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  margin-right: 15px;
  position: relative;
  line-height: 1;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .portfolio__filter button {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio__filter button {
    margin-bottom: 10px;
    padding: 15px;
  }
}
.portfolio__filter button span {
  position: relative;
  z-index: 99;
}
.portfolio__filter button:hover {
  color: var(--whiteColor);
}
.portfolio__filter button.active {
  color: var(--whiteColor);
  background: var(--gradientColor);
}
.portfolio__img {
  position: relative;
}
.portfolio__content {
  padding: 20px 25px;
  background: var(--blackColor);
  border-radius: 10px;
  position: absolute;
  left: 20px;
  bottom: 0;
  transition: var(--transition);
  opacity: 0;
}
.portfolio__content a {
  color: var(--whiteColor);
}
.portfolio__content a:hover {
  color: var(--primaryColor);
}
.portfolio__content h6 {
  margin: 0;
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .portfolio__content h6 {
    font-size: 16px;
  }
}
.portfolio__content span {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
}
.portfolio__single {
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: var(--borderRadius);
}
.portfolio__single:hover .portfolio__content {
  opacity: 1;
  bottom: 20px;
}
.portfolio__single:hover .portfolio__img img {
  transform: scale(1.05);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .portfolio__single {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .portfolio__single {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio__single {
    margin-bottom: 10px;
  }
}
.portfolio__single img {
  border-radius: var(--borderRadius);
}
.portfolio__botton {
  margin-top: 50px;
}
.portfolio .filterDiv {
  display: none; /* Hidden by default */
}
.portfolio .filterDiv.show {
  display: block;
}

@media (min-width: 768px) and (max-width: 991px) {
  .portfolio .section__title.section__title--2 {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .portfolio .section__title.section__title--2 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .portfolio__filter.gridFilter {
    text-align: left !important;
  }
}
@media (max-width: 767px) {
  .portfolio__filter.gridFilter {
    text-align: left !important;
  }
}

.cta__area {
  margin-bottom: 115px;
}
@media (max-width: 767px) {
  .cta__area {
    margin-bottom: 80px;
  }
}
.cta__area__wrap {
  z-index: 999;
}
.cta__area__img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -445px;
}
@media (max-width: 767px) {
  .cta__area__img {
    bottom: -250px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cta__area__img img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .cta__area__img img {
    width: 100%;
  }
}

.cta__icon .cta__img__1 {
  left: 288px;
  bottom: 0;
}
.cta__icon .cta__img__2 {
  top: 5%;
  right: 36%;
}
.cta__icon .cta__img__3 {
  right: 0;
  bottom: 0;
}
.cta__icon .cta__img__4 {
  top: 0;
  left: 0;
}

.contact__input__wrapper {
  padding: 70px 70px 75px 70px;
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderColor);
}
.contact__input__wrapper.contact__grident__bg {
  background: var(--whiteColor);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .contact__input__wrapper {
    padding: 60px 60px 35px 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact__input__wrapper {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .contact__input__wrapper {
    margin-bottom: 30px;
    padding: 20px 20px 25px 20px;
  }
}
.contact__input__wrapper textarea {
  height: 150px;
  padding-top: 25px;
}
.contact__common__input {
  width: 100%;
  height: 58px;
  border: 1px solid var(--borderColor);
  background: var(--whiteColor);
  padding: 0 20px;
  border-radius: var(--borderRadius);
  margin-bottom: 25px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .contact__common__input {
    margin-bottom: 35px;
  }
}
.contact__info {
  padding: 45px 40px 10px 50px;
  background: var(--blackColor);
  margin-bottom: 30px;
  border-radius: var(--borderRadius);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .contact__info {
    padding: 30px 10px 10px 15px;
  }
}
@media (max-width: 767px) {
  .contact__info {
    padding: 30px 10px 10px 15px;
  }
}
.contact__icon {
  margin-right: 25px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .contact__icon {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .contact__icon {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 20px;
  }
}
.contact__icon span {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  line-height: 62px;
  background: var(--whiteColor);
  border-radius: 100%;
  text-align: center;
}
.contact__single__item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.contact__text h6 {
  font-size: 17px;
  font-weight: 400;
  text-decoration-line: underline;
  color: var(--whiteColor);
  margin: 0;
  margin-bottom: 10px;
}
.contact__text p {
  color: var(--whiteColor);
  margin: 0;
}
.contact__img img {
  width: 100%;
}

.contact__heading h3 {
  font-size: 45px;
  line-height: 60px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact__heading h3 {
    font-size: 35px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .contact__heading h3 {
    font-size: 30px;
    line-height: 45px;
  }
}

.contact__info__right {
  padding-left: 60px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact__info__right {
    padding-left: 18px;
  }
}
@media (max-width: 767px) {
  .contact__info__right {
    padding-left: 18px;
  }
}

.contact__map iframe {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .contact__map iframe {
    height: 300px;
  }
}

.bg__black .contact__common__input {
  background: transparent;
  color: var(--whiteColor);
}

.about__list ul {
  display: flex;
  flex-wrap: wrap;
}
.about__list ul li {
  width: 50%;
  margin-bottom: 15px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__list ul li {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .about__list ul li {
    width: 100%;
  }
}
.about__list ul li i {
  background: var(--gradientColor);
  margin-right: 10px;
  color: var(--whiteColor);
  border-radius: 20px;
  height: 20px;
  width: 20px;
  display: inline-block;
  line-height: 21px;
  text-align: center;
}

.bg__black .about__list ul li {
  color: var(--whiteColor);
}

.bg__black .about__inner--2 p {
  color: var(--whiteColor);
}

.single-progress {
  position: relative;
  overflow: hidden;
  margin-top: 30px;
}
.single-progress .title {
  font-weight: 400;
  margin-bottom: 0;
  color: var(--blackColor);
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.progress {
  height: 6px;
  overflow: visible;
  background-color: var(--greyColor);
  border-radius: 10px;
}
.progress .progress-bar {
  border-radius: 10px;
  width: 0;
  height: 100%;
  background-color: var(--primaryColor);
  transition: width 0.5s ease;
  overflow: visible;
}
.progress .progress-bar.progress__gradient {
  background: var(--gradientColor);
}
.progress .progress-number {
  position: absolute;
  font-size: 14px;
  top: 0;
  line-height: 24px;
  right: 0;
  z-index: 1;
  color: var(--blackColor);
}

.skill__sets {
  background: var(--whiteColor);
  padding: 50px 50px 60px;
  border-radius: var(--borderRadius);
}
@media (min-width: 320px) and (max-width: 575px) {
  .skill__sets {
    padding: 20px 20px 30px;
  }
}

.about__hiring__list ul li {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  font-size: 20px;
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 20px;
  padding-left: 10px;
}
.about__hiring__list ul li:last-child {
  border: none;
}
.about__hiring__list ul li svg {
  color: var(--secondaryColor);
  margin-right: 15px;
  width: 35px;
  height: 35px;
}
.about__hiring__list ul li:hover {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  transform: translateY(-5px);
}

.blog__single {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--borderColor2);
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__single {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .blog__single {
    flex-wrap: wrap;
  }
}
.blog__single:hover .blog__date::after {
  opacity: 1;
}
.blog__single:hover .blog__date__inner {
  background: transparent;
}
.blog__single:hover .blog__date__inner span {
  color: var(--whiteColor);
}
.blog__single:hover .blog__date__inner p {
  color: var(--whiteColor);
}
.blog__single:hover .blog__img img {
  transform: rotate(4deg);
}
.blog__section__button.text__end {
  text-align: end;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__section__button.text__end {
    text-align: start;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .blog__section__button.text__end {
    text-align: start;
    margin-top: 30px;
  }
}
.blog__date {
  position: relative;
}
.blog__date__inner {
  background: var(--whiteColor);
  text-align: center;
  width: 164px;
  transition: var(--transition);
  border-radius: var(--borderRadius);
  height: 164px;
  margin-right: 75px;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 15;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 20px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .blog__date__inner {
    margin-right: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__date__inner {
    width: 130px;
    height: 124px;
    margin-right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__date__inner {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .blog__date__inner {
    margin-right: 20px;
    margin-bottom: 30px;
    width: 130px;
    height: 124px;
  }
}
.blog__date::after {
  background: var(--gradientColor);
  position: absolute;
  border-radius: var(--borderRadius);
  left: 0;
  top: 0;
  height: 164px;
  width: 164px;
  content: "";
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  opacity: 0;
  z-index: 9;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__date::after {
    width: 130px;
    height: 124px;
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .blog__date::after {
    width: 130px;
    height: 124px;
  }
}
.blog__date span {
  color: var(--blackColor);
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  width: 100%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__date span {
    font-size: 40px;
    line-height: 50px;
  }
}
.blog__date p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
  width: 100%;
}
.blog__img {
  margin-right: 65px;
  transition: var(--transition);
  width: 250px;
  height: 250px;
  overflow: hidden;
  border-radius: 12px;
}
.blog__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .blog__img {
    width: 100%;
    height: 300px;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .blog__img {
    margin-right: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__img {
    margin-right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__img {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .blog__img {
    margin-right: 20px;
    margin-bottom: 30px;
  }
}
.blog__img img {
  width: 100%;
}
.blog__content {
  max-width: 470px;
  margin-right: 110px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .blog__content {
    margin-right: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__content {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .blog__content {
    margin-right: 20px;
    margin-bottom: 30px;
  }
}
.blog__content h6 {
  margin: 0;
  margin-bottom: 13px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__content h6 {
    margin-bottom: 0px;
  }
}
.blog__content h6 a {
  font-size: 21px;
  font-weight: 600;
  line-height: 33px;
  color: var(--blackColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__content h6 a {
    font-size: 18px;
  }
}
.blog__content h6 a:hover {
  color: var(--primaryColor);
}
.blog__content p {
  line-height: 32px;
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__content p {
    font-size: 15px;
  }
}

.blog__2__icon {
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
.blog__2__small__img {
  text-align: end;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__2__small__img {
    text-align: start;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .blog__2__small__img {
    text-align: start;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.blog__2__img img {
  width: 100%;
}
.blog__2__wrapper {
  padding: 40px 38px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  position: relative;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__2__wrapper {
    padding: 30px 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__2__wrapper {
    padding: 30px 30px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .blog__2__wrapper {
    padding: 30px 30px;
    margin-bottom: 10px;
  }
}
.blog__2__wrapper:hover::before {
  opacity: 0.2;
}
.blog__2__wrapper:hover::after {
  opacity: 0.7;
}
.blog__2__wrapper:before {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  background: url(../assets/img/blog/blog__hover.png);
  top: 0;
  left: 0;
  opacity: 0;
  transition: var(--transition);
  background-size: cover;
}
.blog__2__inner {
  position: relative;
  z-index: 1111;
}
.blog__2__number {
  width: 102px;
  height: 102px;
  background: var(--whiteColor);
  text-align: center;
  transition: var(--transition);
  border-radius: var(--borderRadius);
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 15;
  margin: 0 auto;
  margin-top: -56px;
}
.blog__2__number span {
  color: var(--primaryColor);
  font-size: 50px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 10px;
}
.blog__2__number p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  width: 100%;
}
.blog__2__heading {
  margin-top: 50px;
}
.blog__2__heading h3 a {
  color: var(--whiteColor);
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__2__heading h3 a {
    font-size: 21px;
  }
}
.blog__2__heading h3 a:hover {
  color: var(--primaryColor);
}
.blog__2__text p {
  color: var(--whiteColor);
}
.blog__2__button {
  margin-top: 20px;
}

.blog__4__section__title__text {
  margin-bottom: 30px;
}
.blog__4__padding__right {
  padding-right: 90px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__4__padding__right {
    padding-right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__4__padding__right {
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .blog__4__padding__right {
    padding-right: 0px;
  }
}
.blog__4__single {
  padding: 35px;
  border: 1px solid var(--borderColor2);
  border-radius: var(--borderRadius);
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__4__single {
    padding: 20px;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__4__single {
    padding: 20px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .blog__4__single {
    padding: 20px;
    margin-bottom: 10px;
  }
}
.blog__4__img {
  margin-bottom: 30px;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
}
.blog__4__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog__4__time {
  margin-bottom: 30px;
}
.blog__4__time p {
  font-size: 15px;
  background: var(--pinkcolor);
  padding: 5px 15px;
  border-radius: var(--borderRadius);
  margin: 0;
  display: inline-block;
  font-weight: 600;
}
.blog__4__time p.bg__white {
  background: var(--whiteColor);
}
.blog__4__heading h6 a {
  color: var(--whiteColor);
  font-weight: 700;
  font-size: 22px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__4__heading h6 a {
    font-size: 20px;
  }
}
.blog__4__heading h6 a:hover {
  color: var(--primaryColor);
}
.blog__4__text {
  margin-bottom: 25px;
}
.blog__4__text p {
  margin: 0;
  color: var(--whiteColor);
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.blog__4__button a {
  padding: 15px 35px;
}

.blog__3__single {
  border: none;
}

.bg__pink {
  background: var(--blackColor);
}

.blog__details__img__wrapper {
  padding: 40px;
  margin-bottom: 40px;
  border-radius: var(--borderRadius);
}
.blog__details__img__wrapper img {
  width: 100%;
}
.blog__details__small__button span {
  padding: 10px 15px;
  background: var(--whiteColor);
  color: var(--blackColor);
  border-radius: var(--borderRadius);
}
.blog__details__img {
  width: 100%;
  height: 450px;
  overflow: hidden;
  margin-bottom: 20px;
}
.blog__details__img img {
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
.blog__details__heading {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .blog__details__heading {
    margin-bottom: 10px;
  }
}
.blog__details__heading h4 {
  font-size: 26px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .blog__details__heading h4 {
    font-size: 20px;
  }
}
.blog__details__text {
  margin-bottom: 30px;
}
.blog__details__list {
  margin-bottom: 25px;
}
.blog__details__list ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
}
.blog__details__list ul li::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: var(--contentColor);
  border-radius: 100%;
  top: 13px;
  left: 0;
}
.blog__details__text--2 p {
  padding: 41px 30px;
  background: var(--gradientColor);
  color: var(--whiteColor);
  border-radius: var(--borderRadius);
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  text-decoration-line: underline;
}
.blog__details__social__icon {
  background: var(--pinkcolor);
  display: flex;
  justify-content: center;
  padding: 16px 0;
}
.blog__details__social__icon ul {
  display: flex;
  display: flex;
  align-items: center;
}
.blog__details__social__icon ul li {
  margin-right: 13px;
}
@media (max-width: 767px) {
  .blog__details__social__icon ul li {
    margin-right: 10px;
  }
}
.blog__details__social__icon ul li a {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  background: var(--whiteColor);
  text-align: center;
  border-radius: 100%;
}
.blog__details__social__icon ul li a:hover span {
  z-index: 99;
  position: relative;
  color: var(--whiteColor);
}
.blog__details__social__icon ul li a::after {
  border-radius: 100%;
  transition: var(--transition);
}
@media (max-width: 767px) {
  .blog__details__social__icon ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
.blog__details__share {
  margin-right: 30px;
}
@media (max-width: 767px) {
  .blog__details__share {
    margin-right: 10px;
  }
}
.blog__details__share span {
  font-weight: 700;
}
@media (max-width: 767px) {
  .blog__details__share span {
    font-size: 15px;
  }
}

.project__single {
  margin-bottom: 27px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__single {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__single {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .project__single {
    margin-bottom: 10px;
  }
}
.project__img {
  margin-bottom: 20px;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.project__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__img {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__img {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .project__img {
    margin-bottom: 10px;
  }
}
.project__img img {
  max-width: 100%;
}
.project__section__img.text__end {
  text-align: end;
}
@media (max-width: 767px) {
  .project__section__img.text__end {
    text-align: start;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .project__bottom__button {
    margin-bottom: 30px;
  }
}
.project__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project__icon a {
  background: var(--whiteColor);
  border: 1px solid var(--borderColor);
  display: flex;
  align-items: center;
  justify-content: center;
}
.project__heading {
  max-width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project__heading h3 a {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--blackColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__heading h3 a {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__heading h3 a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .project__heading h3 a {
    font-size: 15px;
    line-height: 22px;
  }
}
.project__heading h3 a:hover {
  color: var(--blackColor);
}
.project__margin {
  background: var(--whiteColor);
  margin-top: -650px;
  border-radius: var(--borderRadius);
  position: relative;
  margin-left: 185px;
  margin-right: 185px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .project__margin {
    margin-left: 100px;
    margin-right: 100px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__margin {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__margin {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 767px) {
  .project__margin {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.project__bottom {
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  padding: 50px 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__bottom {
    padding: 40px 0;
  }
}
.project__bottom__text {
  padding-left: 70px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__bottom__text {
    padding-left: 0;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .project__bottom__text {
    padding-left: 0;
    margin-top: 30px;
  }
}
.project__bottom__text p {
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  color: var(--contentColor);
  margin: 0;
}
.project__bottom__text p a {
  font-style: italic;
  text-decoration-line: underline;
  font-weight: 700;
  color: var(--blackColor);
}
.project__bottom__text p a:hover {
  color: var(--primaryColor);
}
.project__bottom__img {
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
.project__heading--2 {
  position: absolute;
  bottom: 30px;
  left: 35px;
  z-index: 11;
}
.project__heading--2 h3 a {
  color: var(--whiteColor);
}
.project__icon--2 {
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 11;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__icon--2 {
    right: 18px;
  }
}
@media (max-width: 767px) {
  .project__icon--2 {
    right: 10px;
  }
}
.project__img--2 {
  position: relative;
}
.project__img--2::after {
  background: var(--gradientColor2);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  border-radius: var(--borderRadius);
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
}
.project__img--2::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 95%;
  display: block;
  z-index: 1;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    var(--secondaryColor) 120%
  );
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  cursor: pointer;
  opacity: 0;
}
.project__single:hover .project__img--2::after {
  opacity: 0;
}
.project__single:hover .project__img--2::before {
  opacity: 1;
}

.pink__color {
  background: var(--whiteColor);
  height: 790px;
  width: 100%;
}

.project__small__img img {
  position: absolute;
}
.project__small__img img.project__img__2 {
  top: -35px;
  right: -40px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__small__img img.project__img__2 {
    top: -20px;
    right: -25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__small__img img.project__img__2 {
    right: -30px;
  }
}
@media (max-width: 767px) {
  .project__small__img img.project__img__2 {
    right: 20px;
  }
}
.project__small__img img.project__img__1 {
  top: -35px;
  left: -40px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__small__img img.project__img__1 {
    top: -20px;
    left: -25px;
  }
}
@media (max-width: 767px) {
  .project__small__img img.project__img__1 {
    left: 20px;
  }
}

.project__3__name {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 14px;
  background: var(--whiteColor);
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 10px;
  left: 10px;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  border-radius: var(--borderRadius);
}
.project__3__name h6 {
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__3__name h6 {
    font-size: 17px;
    line-height: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__3__name h6 {
    font-size: 20px;
    line-height: 25px;
  }
}
.project__3__icon a {
  height: 62px;
  width: 62px;
  line-height: 62px;
  text-align: center;
  background: var(--blackColor);
  border-radius: 100%;
  display: inline-block;
}
.project__3__img {
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__3__img {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .project__3__img {
    margin-bottom: 20px;
  }
}
.project__3__img img {
  width: 100%;
}
.project__3__img:hover::before {
  opacity: 0.6;
  visibility: visible;
}
.project__3__img:hover .project__3__name {
  opacity: 1;
  visibility: visible;
  bottom: 10px;
}
.project__3__img::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: var(--gradientColor);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
  border-radius: var(--borderRadius);
}
.project__3__button {
  margin-top: 60px;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__3__button {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .project__3__button {
    margin-top: 20px;
  }
}
.project__3 .direction__btn svg path {
  stroke: var(--whiteColor);
}

.project__details__sidebar__info {
  padding: 50px 58px 50px 38px;
  background: var(--gradientColor);
  border-radius: var(--borderRadius);
  margin-bottom: 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__details__sidebar__info {
    padding: 40px 28px 30px 18px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__details__sidebar__info {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .project__details__sidebar__info {
    padding: 15px 15px 15px 14px;
    margin-bottom: 20px;
  }
}
.project__details__sidebar__heading {
  margin-bottom: 25px;
}
.project__details__sidebar__heading.get__quote__heading h6 {
  color: var(--whiteColor);
}
.project__details__sidebar__heading h6 {
  margin: 0;
  color: var(--whiteColor);
  font-size: 26px;
  font-weight: 700;
}
.project__details__sidebar__list ul li {
  margin-bottom: 25px;
  display: block;
}
.project__details__sidebar__list ul li span {
  color: var(--whiteColor);
  margin-bottom: 10px;
  font-weight: 400;
}
.project__details__sidebar__list ul li p {
  margin: 0;
  color: var(--whiteColor);
  font-weight: 700;
}
.project__details__sidebar__icon ul li {
  margin-right: 13px;
}
.project__details__sidebar__icon ul li a {
  background: var(--whiteColor);
  border: none;
}
.project__details__get__quote {
  margin-bottom: 50px;
  background: var(--blackColor);
  padding: 50px 50px 50px 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__details__get__quote {
    padding: 35px 25px 25px 24px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__details__get__quote {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .project__details__get__quote {
    padding: 15px 15px 15px 14px;
    margin-bottom: 20px;
  }
}
.project__details__get__quote__input input {
  border: none;
}
.project__details__get__quote__input textarea {
  height: 250px;
  width: 100%;
  border: none;
}
.project__details__get__quote__button {
  margin-top: 10px;
}
.project__details__challenges {
  padding: 46px 44px 42px 55px;
  background: var(--whiteColor);
  border-radius: var(--borderRadius);
  border: 1px solid var(--borderColor);
  margin: 40px 0;
}
@media (max-width: 767px) {
  .project__details__challenges {
    padding: 26px 10px 12px 25px;
  }
}
.project__details__challenges__heading {
  margin-bottom: 10px;
}
.project__details__challenges__heading h6 {
  color: var(--blackColor);
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}
.project__details__challenges__text {
  font-weight: 600;
  line-height: 32px;
  text-decoration-line: underline;
  color: var(--blackColor);
}
.project__details__button {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .project__details__button {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .project__details__button {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .project__details__button {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.project__details__bottom__border {
  border-bottom: 1px dotted var(--contentColor);
}

.bastun__brand__badge.related__project__badge {
  position: absolute;
  left: 50%;
  top: 35%;
  width: auto;
  transform: translate(-50%, -50%);
}

.projects__nav__wrap {
  display: flex;
  justify-content: center;
}

.projects__nav__wrap .nav-item img {
  width: 100%;
  border-radius: 10px;
}

.projects__img img {
  width: 100%;
}

.projects__nav__wrap .nav-item {
  width: 20%;
  padding: 20px 10px;
  border-radius: 10px;
}

.team {
  overflow: hidden;
}
.team__left {
  background: var(--navyBlue);
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 635px;
  top: 0;
  padding: 80px;
  display: flex;
  align-items: center;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .team__left {
    max-width: 535px;
    padding: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__left {
    max-width: 500px;
    padding: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__left {
    display: none;
  }
}
@media (max-width: 767px) {
  .team__left {
    display: none;
  }
}
.team__left .section__title__heading h3 {
  color: var(--whiteColor);
}
.team__left .section__title__text p {
  color: var(--whiteColor);
  max-width: 90%;
}
.team__left__wrap {
  max-width: 350px;
}
.team__left__img img {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .team__left__img img {
    bottom: -100px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__left__img img {
    bottom: -150px;
  }
}

.team__img {
  margin-bottom: 30px;
  width: 100%;
  height: 230px;
  overflow: hidden;
  border-radius: 12px;
}
@media (max-width: 768px) {
  .team__img {
    height: 300px;
  }
}
.team__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team__name h6 {
  margin: 0;
  margin-bottom: 5px;
}
.team__name h6 a {
  color: var(--whiteColor);
  font-size: 22px;
  font-weight: 700;
  position: relative;
}
.team__name p {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .team__name h6 a {
    font-size: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__name h6 a {
    font-size: 18px;
  }
}
.team__name h6 a:hover {
  color: var(--primaryColor);
}
.team__name h6 a:hover::after {
  background: var(--primaryColor);
}
.team__name h6 a:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--whiteColor);
  left: 0;
  bottom: 0;
}
.team__name span {
  color: var(--whiteColor);
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  opacity: 0.6;
}
.team__right {
  position: relative;
  padding-left: 120px;
  padding-right: 120px;
}
@media (max-width: 767px) {
  .team__right {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__right {
    padding-right: 30px;
  }
}
.team__right [class*="col-"] {
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__right [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__right .row {
    margin: 0 -10px;
  }
}

.team__padding {
  padding-top: 90px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .team__padding {
    padding-top: 100px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__padding {
    padding-top: 110px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__padding {
    padding-top: 110px;
  }
}
@media (max-width: 767px) {
  .team__padding {
    padding-top: 110px;
  }
}

.team__member__single {
  padding: 40px;
  text-align: center;
  border: 1px solid var(--borderColor);
  position: relative;
  border-radius: var(--borderRadius);
  transition: var(--transition);
}
@media (min-width: 1366px) and (max-width: 1499px),
  (min-width: 992px) and (max-width: 1365px),
  (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .team__member__single {
    padding: 20px;
  }
}
.team__member__single:hover {
  border: 1px solid var(--blackColor);
}
.team__member__single:hover .team__member__name h6 a {
  color: var(--whiteColor);
}
.team__member__single:hover .team__member__name p {
  color: var(--whiteColor);
}
.team__member__single::after {
  transform: rotate(5deg);
}
.team__member__single__inner {
  position: relative;
  z-index: 999;
}
.team__member__img {
  margin-bottom: 30px;
  margin-top: -120px;
}
.team__member__name h6 {
  margin: 0;
}
.team__member__name h6 a {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: var(--blackColor);
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .team__member__name h6 a {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__member__name h6 a {
    font-size: 20px;
  }
}
.team__member__name h6 a:hover {
  color: var(--primaryColor);
}
.team__member__name p {
  color: var(--contentColor);
  font-size: 15px;
}
.team__member__icon ul {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: var(--borderRadius);
  padding: 8px 0;
  margin-right: 40px;
  margin-left: 40px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .team__member__icon ul {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.team__member__icon ul li {
  margin-right: 10px;
}
.team__member__icon ul li a {
  height: 52px;
  width: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 15px;
  background: var(--whiteColor);
  display: inline-block;
  border-radius: 100%;
  transition: 0.3s;
}
.team__member__icon ul li a i {
  position: relative;
  z-index: 99;
}
.team__member__icon ul li a:hover {
  color: var(--whiteColor);
}
.team__member__icon--2 ul {
  margin: 0;
}
.team__member__icon--2 ul li a:hover {
  color: var(--primaryColor);
}
.team__member__single__4 {
  padding: 30px;
  display: flex;
  align-items: center;
  border: 1px solid var(--borderColor);
  border-radius: 10px;
  border: 1px solid rgba(10, 6, 36, 0.1);
  background: var(--whiteColor);
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__member__single__4 {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__member__single__4 {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .team__member__single__4 {
    margin-bottom: 10px;
  }
}
.team__member__single__4:hover .team__member__content__4 h6 a {
  color: var(--whiteColor);
}
.team__member__single__4:hover .team__member__content__4 span {
  color: var(--whiteColor);
}
.team__member__img__4 {
  margin-right: 30px;
  position: relative;
  z-index: 1111;
  max-width: 100px;
}
.team__member__img__4 img {
  max-width: 100%;
}
.team__member__content__4 {
  position: relative;
  z-index: 1111;
}
.team__member__content__4 h6 {
  margin: 0;
}
.team__member__content__4 h6 a {
  font-weight: 700;
  line-height: 30px;
  color: var(--blackColor);
  font-size: 20px;
}
.team__member__content__4 h6 a:hover {
  color: var(--primaryColor);
}
.team__member__content__4 span {
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  transition: var(--transition);
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .team__member__3 .container-fluid {
    padding-left: 300px;
    padding-right: 195px;
  }
}
.team__member__3__single {
  background: var(--whiteColor);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: var(--borderRadius);
  border-radius: 10px;
  transition: 0.3s;
  position: relative;
  z-index: 9;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__member__3__single {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__member__3__single {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .team__member__3__single {
    margin-bottom: 10px;
  }
}
.team__member__3__single::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(17 24 39 / 95%);
  transition: var(--transition);
  opacity: 0;
  border-radius: var(--borderRadius);
}
.team__member__3__single__inner {
  position: relative;
  z-index: 99;
  padding-top: 40px;
}
.team__member__3__single:hover .team__member__name h6 a {
  color: var(--whiteColor);
}
.team__member__3__single:hover .team__member__name h6 a:hover {
  color: var(--primaryColor);
}
.team__member__3__single:hover .team__member__name p {
  color: var(--whiteColor);
}
.team__member__3__single:hover::after {
  opacity: 1;
}
.team__member__3__single.team__member__border {
  border: 1px solid var(--borderColor);
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__member__3__single.team__member__border {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__member__3__single.team__member__border {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .team__member__3__single.team__member__border {
    margin-bottom: 10px;
  }
}
.team__member__3__img {
  /*margin-bottom: 30px;*/
  width: 190px;
  height: 190px;
  margin: 10px auto;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.team__member__3__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team__member__3 .team__member__icon ul {
  width: 73%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .team__member__3 .team__member__icon ul {
    width: 100%;
  }
}

.team__details__button {
  margin-top: 40px;
}

.team__details__project__manager {
  background: var(--whiteColor);
  padding: 50px;
  display: flex;
  margin-bottom: 100px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__details__project__manager {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .team__details__project__manager {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .team__details__project__manager {
    padding: 20px;
  }
}

.team__details__project__manager__content {
  width: 75%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__details__project__manager__content {
    width: 55%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__details__project__manager__content {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .team__details__project__manager__content {
    width: 100%;
  }
}

.team__details__top {
  width: 25%;
  margin-right: 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .team__details__top {
    width: 45%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__details__top {
    width: 50%;
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .team__details__top {
    width: 100%;
  }
  .team__details__top.team__member__3__single {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.team__details__project__manager__number {
  display: flex;
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__details__project__manager__number {
    width: 100%;
    flex-wrap: wrap;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .team__details__project__manager__number {
    flex-wrap: wrap;
  }
}

.team__details__project__icon {
  margin-right: 50px;
  display: flex;
  flex-wrap: wrap;
}
.team__details__project__icon a {
  margin-right: 15px;
}
.team__details__project__icon .team__details__number p {
  margin: 0;
  font-size: 15px;
}

.team__details__project__manager__title h6 {
  font-size: 26px;
  margin: 0;
}
.team__details__project__manager__title span {
  margin-top: 10px;
  margin-bottom: 15px;
  display: inline-block;
  font-weight: 500;
}

@media (min-width: 992px) and (max-width: 1365px) {
  .team__single {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .team__single {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .team__single {
    margin-bottom: 15px;
  }
}

.testimonial__inner {
  padding: 70px;
  margin-right: 130px;
  margin-left: 50px;
  border-radius: var(--borderRadius);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .testimonial__inner {
    padding: 30px;
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonial__inner {
    padding: 30px;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .testimonial__inner {
    padding: 30px;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 30px;
  }
}
.testimonial__img {
  position: relative;
}
.testimonial__img img {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonial__img img {
    width: auto;
  }
}
.testimonial__img__2 {
  position: absolute;
  top: -13px;
  left: -12px;
  z-index: -1;
}
.testimonial__small__icon {
  position: absolute;
  top: 0;
  left: -23px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (max-width: 767px) {
  .testimonial__small__icon {
    left: 0;
  }
}
.testimonial__text {
  margin-bottom: 25px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .testimonial__text {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonial__text {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .testimonial__text {
    margin-bottom: 10px;
  }
}
.testimonial__text p {
  font-size: 20px;
  font-weight: 500;
  line-height: 38px;
  margin: 0;
  color: #fff;
}
@media (max-width: 767px) {
  .testimonial__text p {
    font-size: 15px;
    line-height: 30px;
  }
}
.testimonial__author h6 {
  margin: 0;
}
.testimonial__author h6 a {
  font-size: 23px;
  color: var(--whiteColor);
  font-weight: 700;
  line-height: 38px;
}
.testimonial__author span {
  color: var(--primaryColor);
}
.testimonial__bottom {
  position: relative;
}
.testimonial__small__img {
  position: absolute;
  bottom: -20px;
  right: 0;
}
.testimonial__single__slider {
  position: relative;
  margin-right: 1px;
}

.testimonial__2 {
  position: relative;
}
.testimonial__2__single {
  padding: 40px;
  background: var(--pinkcolor);
  position: relative;
  border-radius: var(--borderRadius);
}
@media (max-width: 767px) {
  .testimonial__2__single {
    padding: 20px;
  }
}
.testimonial__2__author {
  display: flex;
  align-items: center;
}
.testimonial__2__author__img {
  margin-right: 30px;
}
.testimonial__2__author__name h6 {
  margin: 0;
}
.testimonial__2__author__name h6 a {
  color: var(--blackColor);
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}
@media (max-width: 767px) {
  .testimonial__2__author__name h6 a {
    font-size: 18px;
  }
}
.testimonial__2__author__name h6 a:hover {
  color: var(--primaryColor);
}
.testimonial__2__icon {
  position: absolute;
  bottom: 36px;
  right: 41px;
}
@media (max-width: 767px) {
  .testimonial__2__icon {
    bottom: 0;
    right: 5px;
  }
}
.testimonial__2 .testimonial__text--2 {
  border-bottom: 1px dashed var(--borderColor2);
  padding-bottom: 50px;
}
.testimonial__2__small__icon {
  position: absolute;
  top: 20px;
  left: -20px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (max-width: 767px) {
  .testimonial__2__small__icon {
    display: none;
  }
}

.testimonial__3 {
  background: var(--blackBlue);
}
.testimonial__3__content {
  padding: 50px 40px 65px;
  background: var(--whiteColor);
  border-radius: var(--borderRadius);
  transition: var(--transition);
}
.testimonial__3__content.bg__pink {
  background: var(--pinkcolor);
}
.testimonial__3__content.bg__pink p {
  color: var(--blackColor);
}
.testimonial__3__content:hover p {
  color: var(--whiteColor);
}
.testimonial__3__content:hover::before {
  border-top: 20px solid #00229e;
}
.testimonial__3__content p {
  text-decoration-line: underline;
  margin: 0px;
  position: relative;
  z-index: 9;
}
.testimonial__3__content::before {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid var(--whiteColor);
  position: absolute;
  content: "";
  bottom: -20px;
  left: 55px;
  transition: 0.3s;
}
.testimonial__3__author {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: 30px;
}
.testimonial__3__img {
  margin-right: 35px;
}
.testimonial__3__name.color__black h6 a {
  color: var(--blackColor);
}
.testimonial__3__name h6 {
  margin: 0;
  font-size: 21px;
  margin-bottom: 5px;
}
.testimonial__3__name h6 a {
  color: var(--whiteColor);
}
.testimonial__3__name h6 a:hover {
  color: var(--primaryColor);
}
.testimonial__3__name p {
  margin: 0;
  color: var(--primaryColor);
}

.testimonial__slider__padding {
  padding-left: 50px;
  padding-right: 50px;
}
@media (max-width: 767px) {
  .testimonial__slider__padding {
    padding-left: 0;
    padding-right: 0;
  }
}

.brand {
  background: var(--gradientColor);
}
.brand__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) and (max-width: 991px) {
  .brand__inner {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .brand__inner {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .brand__single {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .brand__single {
    margin-bottom: 30px;
  }
}
.brand__roated {
  transform: skewY(1deg);
  position: relative;
  top: -20px;
}
.brand__single__counter .counter__number {
  color: var(--whiteColor);
  font-size: 15px;
  margin-bottom: 5px;
}
.brand__single__counter .counter__number span {
  font-size: 32px;
  font-weight: 700;
  color: var(--whiteColor);
}
.brand__single__counter p {
  margin: 0;
  color: var(--whiteColor);
}
.brand--2 {
  background: var(--blackColor);
}
.brand__inner--2 {
  background: var(--gradientColor);
  padding: 40px 60px;
  border-radius: 10px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .brand__inner--2 {
    padding: 20px 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .brand__inner--2 {
    padding: 20px 40px;
  }
}
@media (max-width: 767px) {
  .brand__inner--2 {
    padding: 25px 30px;
  }
}

.brand__margin__bottom {
  top: -150px;
  position: relative;
  z-index: 111;
}

.brand__3 {
  position: absolute;
  width: 100%;
  top: -60px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .brand__3 {
    top: -40px;
  }
}

.facts {
  background: var(--blackColor);
  margin: 50px 0;
}
.facts__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .facts__inner {
    flex-wrap: wrap;
  }
}
.facts__inner--3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 992px) and (max-width: 1365px),
  (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .facts__inner--3 {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .facts__inner--3 .facts__single--3 {
    width: 33%;
    margin: 10px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .facts__inner--3 .facts__single--3 {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .facts__inner--3 .facts__single--3 {
    margin-right: 0;
    width: 50%;
  }
}
.facts__roated {
  transform: rotate(1deg);
}
.facts__single__counter .counter__number {
  color: var(--whiteColor);
  font-size: 15px;
  margin-bottom: 5px;
  line-height: 1;
}
.facts__single__counter .counter__number span {
  font-size: 32px;
  font-weight: 700;
  color: var(--whiteColor);
}
.facts__single__counter p {
  margin: 0;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .facts__single {
    margin: 10px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .facts__single img {
    display: none;
  }
}
.facts__single--3 {
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.facts__single--3:last-child {
  margin-right: 0;
}
.facts__single--3 .facts__img {
  margin-right: 20px;
}
.facts__icon {
  margin-left: 50px;
}

.brand__animate {
  white-space: nowrap;
  position: relative;
  will-change: transform;
  animation: marquee 50s linear 0s infinite normal;
  animation-play-state: running;
}
.brand__animate:hover {
  animation-play-state: paused;
}
.brand__animate .brand__single {
  min-width: 10%;
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .brand__animate .brand__single {
    min-width: 15%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .brand__animate .brand__single {
    min-width: 20%;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .brand__animate .brand__single {
    min-width: 25%;
    margin: 0;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .brand__animate .brand__single {
    min-width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .brand__animate {
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .brand__animate {
    flex-wrap: nowrap;
  }
}

/* service__features */
.service__features__wrap {
  background: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 50px;
  border-radius: 10px;
  padding: 65px 50px 30px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .service__features__wrap {
    padding: 35px 20px 10px;
  }
}
.service__features__item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .service__features__item {
    flex-wrap: wrap;
  }
}
.service__features__item:hover .service__features__icon img {
  transform: rotateY(180deg);
}
.service__features__icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 20px;
  color: var(--primaryColor);
  font-size: 40px;
  position: relative;
  line-height: 0;
  border-radius: 14px;
  background: var(--secondaryColor);
}
@media (min-width: 320px) and (max-width: 575px) {
  .service__features__icon {
    margin-bottom: 10px;
  }
}
.service__features__icon img {
  transition: 0.3s linear;
  transform: rotateY(0);
  max-width: 50px;
}
.service__features__icon::before {
  content: "";
  position: absolute;
  right: 0;
  top: 10px;
  width: 4px;
  height: 30px;
  background: var(--primaryColor);
  border-radius: 2px;
}
.service__features__icon::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 4px;
  height: 30px;
  background: var(--primaryColor);
  border-radius: 2px;
}
.service__features__content .service__name {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}
.service__features__content p {
  margin-bottom: 0;
  color: var(--contentColor);
  font-size: 16px;
  line-height: 26px;
}
.service__features__position {
  bottom: -105px;
  position: absolute;
  left: 0;
  right: 0;
}

.simple__banners__item {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
}
.simple__banners__item img {
  width: 100%;
}
.simple__banners__item:hover img {
  transform: scale(1.05);
}

.breadcrumbarea {
  padding: 120px 0;
  background-position: center center !important;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .breadcrumbarea {
    padding: 50px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumbarea {
    padding: 50px 0;
  }
}
@media (max-width: 767px) {
  .breadcrumbarea {
    padding: 50px 0;
  }
}
.breadcrumbarea__title h2 {
  font-size: 60px;
  font-weight: 700;
  color: var(--whiteColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .breadcrumbarea__title h2 {
    font-size: 30px;
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumbarea__title h2 {
    font-size: 30px;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .breadcrumbarea__title h2 {
    font-size: 24px;
    margin-bottom: 5px;
  }
}
.breadcrumbarea__inner ul li {
  color: var(--whiteColor);
}
.breadcrumbarea__inner ul li a {
  color: var(--whiteColor);
}

/*------------------------------
Grid Area
-------------------------------*/
.gridarea {
  padding-top: 100px;
  padding-bottom: 70px;
  background: var(--lightGrey7);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .gridarea {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .gridarea {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}
.gridarea.gridarea__2 {
  background: var(--whiteColor);
}

.gridarea__wraper {
  background: var(--whiteColor);
  padding: 15px;
  box-shadow: 0 0 5px 2px rgba(95, 45, 237, 0.05);
  margin-bottom: 20px;
}
.gridarea__wraper.gridarea__wraper__2 .gridarea__content .gridarea__heading h3 {
  font-size: 18px;
  line-height: 25px;
}
.gridarea__wraper.gridarea__wraper__2
  .gridarea__content
  .gridarea__bottom
  .gridarea__small__img
  .gridarea__small__content
  h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.gridarea__wraper:hover .gridarea__img img {
  transform: scale(1.05);
}
.gridarea__wraper .gridarea__img {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}
.gridarea__wraper .gridarea__img img {
  width: 100%;
  border-radius: var(--borderRadius);
}
.gridarea__wraper .gridarea__img .gridarea__small__button {
  position: absolute;
  top: 3px;
  left: 8px;
}
.gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge {
  background: var(--secondaryColor);
  color: var(--whiteColor);
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  line-height: 1;
}
.gridarea__wraper
  .gridarea__img
  .gridarea__small__button
  .grid__badge.blue__color {
  background: var(--primaryColor);
}
.gridarea__wraper
  .gridarea__img
  .gridarea__small__button
  .grid__badge.pink__color {
  background: var(--navyBlue);
}
.gridarea__wraper
  .gridarea__img
  .gridarea__small__button
  .grid__badge.green__color {
  background: var(--violet);
}
.gridarea__wraper
  .gridarea__img
  .gridarea__small__button
  .grid__badge.orange__color {
  background: var(--secondaryColor);
}
.gridarea__wraper
  .gridarea__img
  .gridarea__small__button
  .grid__badge.yellow__color {
  background: var(--yellow);
}
.gridarea__wraper .gridarea__img .gridarea__small__icon {
  position: absolute;
  top: 3px;
  right: 8px;
}
.gridarea__wraper .gridarea__img .gridarea__small__icon i {
  width: 30px;
  height: 27px;
  line-height: 27px;
  background: rgba(0, 0, 0, 0.16);
  display: inline-block;
  text-align: center;
  color: var(--whiteColor);
  border-radius: 3px;
  transition: var(--transition);
}
.gridarea__wraper .gridarea__img .gridarea__small__icon i:hover {
  background: var(--primaryColor);
}
.gridarea__wraper .gridarea__content .gridarea__list {
  margin-bottom: 15px;
}
.gridarea__wraper .gridarea__content .gridarea__list ul {
  display: flex;
}
@media (max-width: 767px) {
  .gridarea__wraper .gridarea__content .gridarea__list ul {
    flex-wrap: wrap;
  }
}
.gridarea__wraper .gridarea__content .gridarea__list ul li {
  font-size: 14px;
  width: 50%;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .gridarea__wraper .gridarea__content .gridarea__list ul li {
    width: 100%;
  }
}
.gridarea__wraper .gridarea__content .gridarea__list ul li i {
  font-size: 18px;
  color: var(--primaryColor);
  margin-right: 5px;
}
.gridarea__wraper .gridarea__content .gridarea__heading h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.gridarea__wraper .gridarea__content .gridarea__heading h3 a {
  color: var(--blackColor);
}
.gridarea__wraper .gridarea__content .gridarea__heading h3 a:hover {
  color: var(--primaryColor);
}
.gridarea__wraper .gridarea__content .gridarea__price {
  font-size: 18px;
  font-weight: 600;
  color: var(--primaryColor);
  margin-bottom: 20px;
}
.gridarea__wraper .gridarea__content .gridarea__price.green__color {
  color: var(--lightGrey4);
}
.gridarea__wraper .gridarea__content .gridarea__price.green__color span {
  color: var(--greencolor);
  font-size: 16px;
}
.gridarea__wraper .gridarea__content .gridarea__price del {
  color: var(--blackColor);
  font-size: 13px;
}
.gridarea__wraper .gridarea__content .gridarea__price span {
  margin-left: 25px;
}
.gridarea__wraper .gridarea__content .gridarea__price span del {
  color: var(--secondaryColor3);
  font-size: 16px;
}
.gridarea__wraper .gridarea__content .gridarea__bottom {
  border-top: 1px solid var(--borderColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}
.gridarea__wraper .gridarea__content .gridarea__bottom a {
  line-height: 1;
  font-size: 15px;
}
@media (max-width: 767px) {
  .gridarea__wraper .gridarea__content .gridarea__bottom {
    flex-wrap: wrap;
  }
}
.gridarea__wraper .gridarea__content .gridarea__bottom h6:hover {
  color: var(--primaryColor);
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__small__img {
  display: flex;
  align-items: center;
}
.gridarea__wraper
  .gridarea__content
  .gridarea__bottom
  .gridarea__small__img
  img {
  max-width: 30px;
  border-radius: 50px;
}
.gridarea__wraper
  .gridarea__content
  .gridarea__bottom
  .gridarea__small__content {
  margin-left: 15px;
}
.gridarea__wraper
  .gridarea__content
  .gridarea__bottom
  .gridarea__small__content
  h6 {
  margin-bottom: 0;
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__star {
  color: var(--yellow);
  font-size: 15px;
  line-height: 1;
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__star span {
  color: var(--lightGrey6);
  font-size: 13px;
}

.grid__filter {
  padding: 20px 40px;
  background: var(--whiteColor);
  margin-bottom: 25px;
  box-shadow: 0px 30px 40px 0px rgba(1, 11, 60, 0.06);
  margin-right: 50px;
  margin-left: 50px;
  border-radius: var(--borderRadius);
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter {
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter {
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .grid__filter {
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
  }
}
.grid__filter button {
  border: none;
  background: none;
  margin: 0 15px;
  padding: 0 15px;
  position: relative;
  font-weight: 500;
  color: var(--contentColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter button {
    margin: 0 12px;
    padding: 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button {
    margin: 0 8px;
    padding: 0 8px;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .grid__filter button {
    margin: 0 10px;
    padding: 0 10px;
  }
}
.grid__filter button::before {
  width: 0;
  transition: var(--transition);
  position: absolute;
  content: "";
  height: 2px;
  background: var(--primaryColor);
  bottom: -20px;
  left: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter button::before {
    bottom: -10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button::before {
    bottom: -15px;
  }
}
@media (max-width: 767px) {
  .grid__filter button::before {
    bottom: -3px;
  }
}
.grid__filter button:hover::before {
  width: 100%;
}
.grid__filter button:last-child {
  margin-right: 0;
  padding-right: 0;
}
.grid__filter button:last-child::after {
  display: none;
}
.grid__filter button:hover {
  color: var(--primaryColor);
}
.grid__filter button.active {
  color: var(--primaryColor);
}
.grid__filter button.active::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--primaryColor);
  bottom: -20px;
  left: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter button.active::before {
    bottom: -10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button.active::before {
    bottom: -15px;
  }
}
@media (max-width: 767px) {
  .grid__filter button.active::before {
    bottom: -3px;
  }
}
.grid__filter button::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background: var(--contentColor);
  bottom: 8px;
  right: -15px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button::after {
    bottom: 2px;
  }
}

.gridarea__wraper.gridarea__course__list {
  display: flex;
  margin-bottom: 30px;
  padding-right: 30px;
}
@media (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list {
    flex-wrap: wrap;
    padding-right: 15px;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__img {
  margin-bottom: 0;
  width: 35%;
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list .gridarea__img {
    margin-right: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__content {
  width: 65%;
  padding-left: 90px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content {
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content {
    padding-left: 0;
    width: 100%;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading {
  max-width: 95%;
}
.gridarea__wraper.gridarea__course__list
  .gridarea__content
  .gridarea__heading
  h3 {
  font-weight: 600;
  font-size: 34px;
  line-height: 37px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .gridarea__wraper.gridarea__course__list
    .gridarea__content
    .gridarea__heading
    h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list
    .gridarea__content
    .gridarea__heading
    h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list
    .gridarea__content
    .gridarea__heading
    h3 {
    font-size: 20px;
    line-height: 25px;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__price {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(114, 106, 137, 0.8);
}
.gridarea__wraper.gridarea__course__list
  .gridarea__content
  .gridarea__price
  span {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--greencolor2);
}
.gridarea__wraper.gridarea__course__list
  .gridarea__content
  .gridarea__bottom
  .gridarea__details
  a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--blackColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list
    .gridarea__content
    .gridarea__bottom
    .gridarea__details
    a {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list
    .gridarea__content
    .gridarea__bottom
    .gridarea__details
    a {
    font-size: 13px;
  }
}
.gridarea__wraper.gridarea__course__list
  .gridarea__content
  .gridarea__bottom
  .gridarea__details
  a:hover {
  color: var(--primaryColor);
}

.gridarea__bottom__left {
  display: flex;
  align-items: center;
}
.gridarea__bottom__left .gridarea__star {
  margin-left: 35px;
}

.grid__row {
  align-items: center;
  margin-bottom: 25px;
}

.grid__filter__2 {
  text-align: right;
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter__2 {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .grid__filter__2 {
    text-align: left;
  }
}
.grid__filter__2 button {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  padding-right: 40px;
  color: var(--contentColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter__2 button {
    padding-right: 17px;
  }
}
@media (max-width: 767px) {
  .grid__filter__2 button {
    padding-right: 20px;
  }
}
.grid__filter__2 button:last-child {
  padding-right: 0;
}
.grid__filter__2 button.active {
  color: var(--primaryColor);
}
.grid__filter__2 button:hover {
  color: var(--primaryColor);
}

.product__grid:hover .product__grid__action {
  bottom: 20px;
  visibility: visible;
  opacity: 1;
}
.product__grid__action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.product__grid__action ul {
  display: flex;
  justify-content: center;
}
.product__grid__action ul li {
  margin: 0 3px;
}
.product__grid__action ul li a,
.product__grid__action ul li button {
  height: 45px;
  width: 50px;
  display: block;
  border: 1px solid var(--borderColor);
  text-align: center;
  line-height: 45px;
  border-radius: var(--borderRadius);
  background-color: var(--whiteColor);
  font-size: 14px;
}
.product__grid__action ul li a:hover,
.product__grid__action ul li button:hover {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.product__grid__action ul li a.grid__cart,
.product__grid__action ul li button.grid__cart {
  width: auto;
  padding: 0 20px;
  line-height: 42px;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .product__grid__action ul li a.grid__cart,
  .product__grid__action ul li button.grid__cart {
    padding: 0 5px;
  }
}
.product__grid__action ul li svg {
  height: 18px;
  position: relative;
  top: -2px;
}
.product__grid__action ul li svg path {
  transition: var(--transition);
}

#list_four .product__grid__action ul li {
  margin: 0 2px;
}

#list_four .product__grid__action ul li a.grid__cart {
  padding: 0 5px;
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__heading h3 {
  font-size: 17px;
  line-height: 1;
  margin-bottom: 15px;
  font-weight: normal;
}
@media (max-width: 767px) {
  .gridarea__wraper.product__grid .gridarea__content .gridarea__heading h3 {
    font-size: 16px;
  }
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__price {
  line-height: 1;
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__price {
  font-size: 18px;
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__price del {
  font-size: 16px;
}

.zoom__meeting__grid.gridarea__wraper .gridarea__content .gridarea__heading h3 {
  font-size: 22px;
  line-height: 1;
}
@media (max-width: 767px) {
  .zoom__meeting__grid.gridarea__wraper
    .gridarea__content
    .gridarea__heading
    h3 {
    font-size: 18px;
  }
}
.zoom__meeting__grid .zoom__meeting__id p {
  font-size: 14px;
}
.zoom__meeting__grid .zoom__meeting__id span {
  font-weight: 700;
  color: var(--secondaryColor);
  font-size: 18px;
  margin: 10px;
}
@media (max-width: 767px) {
  .zoom__meeting__grid .zoom__meeting__id span {
    font-size: 14px;
  }
}
.zoom__meeting__grid .zoom__meeting__time p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.zoom__meeting__grid .zoom__meeting__time span {
  font-weight: 700;
  color: var(--primaryColor);
  font-size: 26px;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .zoom__meeting__grid .zoom__meeting__time span {
    font-size: 20px;
  }
}
.zoom__meeting__grid
  .gridarea__content
  .gridarea__bottom
  .gridarea__small__content {
  margin-left: 15px;
  font-size: 12px;
}
.zoom__meeting__grid
  .gridarea__content
  .gridarea__bottom
  .gridarea__small__content
  h5 {
  margin: 0;
  font-size: 18px;
}
.zoom__meeting__grid
  .gridarea__content
  .gridarea__bottom
  .gridarea__small__img
  img {
  max-width: 50px;
}

.zoom__meetings__details .course__details__sidebar--2 {
  top: 0;
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .featurearea {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .featurearea {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .featurearea {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.featurearea__img img {
  width: 100%;
}
.featurearea__small__title {
  margin-bottom: 10px;
  line-height: 1;
}
.featurearea__small__title span {
  font-size: 18px;
  line-height: 1.2;
  color: var(--blackColor);
}
.featurearea__main__title h3 {
  font-size: 30px;
  margin-bottom: 15px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__main__title h3 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .featurearea__main__title h3 {
    font-size: 21px;
    margin-bottom: 10px;
  }
}
.featurearea__price span {
  font-size: 18px;
  margin-right: 6px;
  color: var(--blackColor);
}
.featurearea__price span.featurearea__price__button {
  padding: 5px 10px;
  background: var(--primaryColor);
  color: var(--whiteColor);
  font-size: 15px;
  border-radius: var(--borderRadius);
}
.featurearea__price span.featurearea__price__button.black__color {
  background: var(--blackColor);
}
.featurearea__countdown__title {
  display: flex;
  margin-top: 20px;
}
.featurearea__countdown__title svg {
  color: var(--blackColor);
}
.featurearea__countdown__title h5 {
  margin-left: 6px;
  font-weight: 500;
  font-size: 18px;
}
.featurearea__countdown {
  display: flex;
  margin-bottom: 25px;
}
.featurearea__countdown .count {
  margin-right: 6px;
  background: var(--gradientColor);
  text-align: center;
  line-height: 1;
  border-radius: 10px;
  display: flex;
  height: 65px;
  width: 70px;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__countdown .count {
    height: 60px;
    width: 70px;
  }
}
@media (max-width: 767px) {
  .featurearea__countdown .count {
    height: 60px;
    width: 60px;
  }
}
.featurearea__countdown .count p {
  margin-bottom: 5px;
  color: var(--whiteColor);
  font-size: 18px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__countdown .count p {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .featurearea__countdown .count p {
    font-size: 15px;
  }
}
.featurearea__countdown .count span {
  color: var(--whiteColor);
  font-size: 14px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__countdown .count span {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .featurearea__countdown .count span {
    font-size: 15px;
  }
}
.featurearea .progress {
  height: 4px;
  max-width: 75%;
}
.featurearea .progress .progress-bar {
  background: var(--gradientColor);
}
.featurearea__progress__text h6 {
  color: var(--blackColor);
}
.featurearea__size {
  margin-top: 20px;
  margin-bottom: 7px;
  color: var(--blackColor);
}
.featurearea__size__button ul li button {
  height: 40px;
  width: 40px;
  line-height: 36px;
  color: var(--blackColor);
  border: 1px solid var(--borderColor);
  display: inline-block;
  text-align: center;
  border-radius: var(--borderRadius);
  background-color: var(--whiteColor);
}
.featurearea__size__button ul li button:hover {
  background: var(--blackColor);
  color: var(--whiteColor);
}
.featurearea__size__img ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.featurearea__size__img ul li button {
  background: var(--whiteColor);
}
.featurearea__size__img ul li button img {
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
  padding: 2px;
  max-width: 50px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__size__img ul li button img {
    max-width: 40px;
  }
}
@media (max-width: 767px) {
  .featurearea__size__img ul li button img {
    max-width: 36px;
  }
}
.featurearea__quantity__button {
  margin-right: 20px;
  padding: 10px 37px;
  border: 2px solid var(--blackColor);
  border-radius: var(--borderRadius);
  color: var(--blackColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .featurearea__quantity__button {
    margin-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__quantity__button {
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .featurearea__quantity__button {
    margin-right: 10px;
  }
}
.featurearea__quantity__button:hover a {
  color: var(--primaryColor);
}
.featurearea__quantity {
  display: flex;
  margin-bottom: 30px;
}
.featurearea__bottom__button a {
  background: var(--blackColor);
  color: var(--whiteColor);
  padding: 15px 130px;
  border-radius: var(--borderRadius);
  display: inline-block;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__bottom__button a {
    padding: 15px 90px;
  }
}
.featurearea__bottom__button a:hover {
  background: var(--primaryColor);
}
@media (max-width: 767px) {
  .featurearea__bottom__button a {
    padding: 12px 60px;
  }
}
.featurearea__thumb__img {
  padding-top: 10px;
}
.featurearea__thumb__img.slider__default__arrow .slick-arrow {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}
.featurearea__single__big__img {
  border: 1px solid var(--borderColor);
}
.featurearea__single__big__img img {
  width: 100%;
}
.featurearea__single__thumb__img {
  border: 1px solid var(--borderColor);
  cursor: pointer;
}
.featurearea__single__thumb__img img {
  width: 100%;
}

.featurearea__desc {
  margin-top: 15px;
}

.tabcollectionarea__item__wrap {
  margin-bottom: 20px;
}
.tabcollectionarea__item__wrap.text-center {
  justify-content: center;
}
.tabcollectionarea__product__item__link {
  border: none;
  margin: 0 30px 0 0;
  background: none;
  font-size: 20px;
  font-weight: 500;
}
.tabcollectionarea__product__item__link:hover {
  color: var(--primaryColor);
}
.tabcollectionarea__product__item__link.active {
  color: var(--primaryColor);
}

.descriptionarea__tab__wrapper {
  border: 1px solid var(--borderColor);
  padding: 30px 30px 37px;
}
.descriptionarea__link {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 1;
  position: relative;
  padding: 0 0 10px;
  margin: 0 32px 15px 0;
  border: none;
  background: none;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .descriptionarea__link {
    margin: 0 15px 15px 0;
  }
}
.descriptionarea__link.active:after {
  width: 100%;
}
.descriptionarea__link:hover:after {
  width: 100%;
}
.descriptionarea__link:after {
  content: "";
  height: 2px;
  left: 0;
  width: 0;
  position: absolute;
  -webkit-transition: width 0.3s ease-out;
  -o-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out;
  background-color: var(--primaryColor);
  bottom: 0;
}

@media (min-width: 768px) {
  .featurearea__details__img.products__details__gallery {
    position: sticky;
    top: 100px;
    margin-bottom: 0;
  }
}
.cart__pm__btn__wrap {
  display: flex;
  align-items: center;
}

.cartarea__table__content table {
  border: 1px solid var(--borderColor);
  width: 100%;
}
.cartarea__table__content table thead tr {
  background-color: var(--greywhite);
  border: 1px solid var(--borderColor);
}
.cartarea__table__content table thead tr th {
  border-top: medium none;
  font-size: 14px;
  font-weight: 600;
  padding: 21px 45px 22px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  color: var(--blackColor);
  min-width: 100px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .cartarea__table__content table thead tr th {
    padding: 21px 25px 22px;
  }
}
@media (max-width: 767px) {
  .cartarea__table__content table thead tr th {
    padding: 13px 4px 9px;
    font-size: 14px;
  }
}
.cartarea__table__content table tbody tr {
  border-bottom: 1px solid var(--borderColor);
  color: var(--blackColor);
}
.cartarea__table__content table tbody tr td {
  font-size: 16px;
  text-align: center;
  border: 1px solid var(--borderColor);
  padding: 20px 10px;
}
.cartarea__table__content table tbody tr td a img {
  max-width: 80px;
}
.cartarea__product__remove button {
  color: var(--contentColor);
  transition: 0.3s;
  background: var(--whiteColor);
}
.cartarea__product__remove button:hover {
  color: var(--primaryColor);
}
.cartarea__table__content table tbody tr td button svg {
  width: 25px;
}
.cartarea__product__name {
  width: 435px;
}
.cartarea__product__quantity {
  width: 435px;
  min-width: 200px;
}
.cartarea__plus__minus {
  display: inline-block;
  height: 58px;
  padding: 0;
  position: relative;
  border: 2px solid var(--borderColor2);
  overflow: hidden;
  max-width: 150px;
}

@media (max-width: 767px) {
  .cartarea__plus__minus {
    width: 90px;
  }
}
.cartarea__plus__minus .qtybutton {
  color: var(--blackColor);
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 20px;
  text-align: center;
}
.cartarea__plus__minus .qtybutton.dec {
  left: 10px;
  top: 11px;
}
.cartarea__plus__minus .qtybutton.inc {
  right: 10px;
  top: 11px;
}
.cartarea__plus__minus .input-qty.input-qty {
  height: 100%;
  border: none;
  text-align: center;
  width: 100%;
}
.cartarea__plus__minus__box {
  color: var(--blackColor);
  float: left;
  font-size: 14px;
  height: 55px;
  margin: 0;
  width: 100%;
  background: transparent none repeat scroll 0 0;
  border: none;
  padding: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .cartarea__plus__minus__box {
    width: 90px;
  }
}
.cartarea__product__thumbnail a img {
  width: 100%;
}
.cartarea__shiping__update__wrapper {
  padding: 30px 0 55px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .cartarea__shiping__update__wrapper {
    padding: 22px 0 36px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
}
.cartarea__shiping__update a {
  background-color: var(--blackColor);
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 55px;
  padding: 0 40px;
  height: 55px;
  border-radius: var(--borderRadius);
  text-transform: uppercase;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .cartarea__shiping__update a {
    padding: 0 20px;
  }
}
.cartarea__shiping__update a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.cartarea__clear button {
  background-color: var(--blackColor);
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 55px;
  padding: 0 40px;
  height: 55px;
  border-radius: var(--borderRadius);
  text-transform: uppercase;
  margin-right: 20px;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .cartarea__clear button {
    padding: 0 20px;
  }
}
.cartarea__clear button:last-child {
  margin-right: 0;
}
.cartarea__clear button:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.cartarea__tax {
  background-color: var(--greywhite);
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  padding: 45px 30px 50px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .cartarea__tax {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .cartarea__tax {
    margin-bottom: 30px;
  }
}
.cartarea__title {
  position: relative;
}
.cartarea__title::after {
  background-color: var(--borderColor2);
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}
.cartarea__title h4 {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding-right: 18px;
  background-color: var(--whiteColor);
  position: relative;
  z-index: 99;
}
.cartarea__text {
  margin-top: 22px;
}
.cartarea__tax__select {
  margin-bottom: 20px;
}
.cartarea__tax__select label {
  color: var(--blackColor);
}
.cartarea__tax__select select {
  box-shadow: none;
  font-size: 12px;
  height: 40px;
  padding: 0 50px 0 15px;
  width: 100%;
  cursor: pointer;
}
.cartarea__code {
  margin-bottom: 20px;
}
.cartarea__code label {
  color: var(--blackColor);
}
.cartarea__code input {
  box-shadow: none;
  font-size: 12px;
  height: 40px;
  padding: 0 50px 0 15px;
  width: 100%;
  cursor: pointer;
  border: 1px solid var(--borderColor2);
}
.cartarea__discount__code {
  margin: 21px 0 0;
}
.cartarea__discount__code textarea {
  border: 1px solid var(--borderColor2);
  background: var(--whiteColor);
  width: 100%;
  height: 100px;
}
.cartarea__grand__totall h5 {
  font-size: 14px;
  margin: 36px 0 27px;
}
.cartarea__grand__totall h5 span {
  float: right;
  font-size: 18px;
  font-weight: 500;
}
.cartarea__grand__totall a {
  color: var(--whiteColor);
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 55px;
  padding: 0 10px;
  height: 55px;
  text-align: center;
  text-transform: uppercase;
  background: var(--blackColor);
  border-radius: var(--borderRadius);
}
.cartarea__grand__totall a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

.swiper.modal__gallery {
  margin-top: 10px;
}

.default__button.sidebar-collapse-btn {
  display: none;
}

@media (max-width: 991px) {
  .sidebar-collapse-hide {
    display: none;
  }
  .sidebar__filter__responsive {
    order: -1;
  }
  .sidebar__filter__responsive .sidebar-collapse-btn {
    display: flex;
  }
  .default__button.sidebar-collapse-btn {
    width: 100%;
    margin-bottom: 20px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shoptab {
    margin-bottom: 20px;
  }
  .default__button.sidebar-collapse-btn svg {
    fill: var(--whiteColor);
    padding-right: 5px;
  }
}
.footer {
  background-size: cover;
  background-repeat: no-repeat;
}
.footer__left {
  padding-right: 20px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .footer__left {
    padding-right: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__left {
    padding-right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__left {
    padding-right: 0px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer__left {
    padding-right: 0px;
  }
}
.footer__logo {
  margin-bottom: 40px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__logo {
    margin-bottom: 20px;
  }
}
.footer__text {
  margin-bottom: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__text {
    margin-bottom: 10px;
  }
}
.footer__text p {
  color: var(--whiteColor);
  font-weight: 400;
  line-height: 40px;
  margin: 0;
}
.footer__icon ul li {
  margin-right: 10px;
}
.footer__icon ul li a {
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  border-radius: 100%;
  background: var(--whiteColor);
  display: inline-block;
  color: var(--blackColor);
  font-size: 15px;
  font-weight: 400;
}

.footer__support {
  padding-left: 50px;
}
@media (max-width: 767px) {
  .footer__support {
    padding-left: 0px;
    margin-top: 30px;
  }
}
.footer__menu__title {
  margin-bottom: 56px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .footer__menu__title {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__menu__title {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__menu__title {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer__menu__title {
    margin-bottom: 20px;
  }
}
.footer__menu__title h6 {
  font-size: 18px;
  font-weight: 700;
  color: var(--whiteColor);
  position: relative;
  margin: 0;
  line-height: 1.7;
  display: inline-block;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__menu__title h6 {
    font-size: 16px;
  }
}
.footer__menu__title h6:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--whiteColor);
  left: 0;
  bottom: 0;
}
.footer__menu ul li {
  display: block;
}
.footer__menu ul li a {
  color: var(--whiteColor);
  font-weight: 400;
  margin-bottom: 15px;
  display: block;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__menu ul li a {
    margin-bottom: 5px;
    font-size: 14px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__menu ul li a {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .footer__menu ul li a {
    margin-bottom: 5px;
  }
}
.footer__menu ul li a:hover {
  color: var(--primaryColor);
}
.footer__quick {
  padding-left: 30px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .footer__quick {
    padding-left: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__quick {
    padding-left: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__quick {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .footer__quick {
    padding-left: 0px;
    margin-top: 30px;
  }
}
.footer__right {
  padding-left: 70px;
}
@media (max-width: 767px) {
  .footer__right {
    padding-left: 0px;
    margin-top: 30px;
  }
}
.footer__input {
  margin-bottom: 20px;
}
.footer__input input {
  width: 100%;
  padding: 10px 20px;
  background: var(--whiteColor);
  border-radius: 4px;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  border: 1px solid var(--whiteColor);
  height: 60px;
}
.footer__gallery {
  margin-left: 40px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .footer__gallery {
    margin-left: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__gallery {
    margin-left: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__gallery {
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
  .footer__gallery {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.footer__gallery__icon a {
  height: 42px;
  width: 42px;
  line-height: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 999;
}
.footer__single__gallery__img {
  position: relative;
}
.footer__single__gallery__img:hover .footer__gallery__icon a {
  opacity: 1;
}
.footer__single__gallery__img:hover:after {
  opacity: 1;
}
.footer__single__gallery__img:after {
  background: var(--gradientColor);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  opacity: 0;
  border-radius: var(--borderRadius);
}
.footer__gallery__img {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.footer__gallery__img img {
  width: 100%;
}
.footer__right--2 {
  padding-left: 30px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .footer__right--2 {
    padding-left: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footer__right--2 {
    padding-left: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__right--2 {
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .footer__right--2 {
    padding-left: 0px;
  }
}

.copyright {
  background-color: rgba(255, 240, 250, 0.05);
  padding: 15px 47px;
  border-radius: var(--borderRadius);
  position: relative;
  border-radius: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .copyright {
    padding: 15px 10px;
  }
}
@media (max-width: 767px) {
  .copyright {
    padding: 15px 10px;
  }
}
.copyright--2 {
  background: var(--gradientColor);
  position: relative;
}
.copyright--2::before {
  position: absolute;
  content: "";
  width: 52%;
  height: 81px;
  background: var(--blackColor);
  left: -20px;
  top: -11px;
  transform: skew(-25deg);
  border-right: 5px solid var(--whiteColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .copyright--2::before {
    width: 54%;
    left: -25px;
  }
}
@media (max-width: 767px) {
  .copyright--2::before {
    display: none;
  }
}
.copyright--4 {
  background: var(--gradientColor);
  border-radius: 0;
}
.copyright__icon ul {
  justify-content: right;
}
.copyright__icon ul li a {
  color: var(--whiteColor);
}
.copyright__left--2 p {
  position: relative;
  z-index: 111;
}
.copyright__left.copyright__left--2 p a {
  color: var(--violet);
}
.copyright__left p {
  font-weight: 400;
  line-height: 40px;
  color: var(--whiteColor);
  margin: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .copyright__left p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .copyright__left p {
    font-size: 14px;
  }
}
.copyright__left p a {
  color: var(--primaryColor);
  font-weight: 700;
}
.copyright__right {
  text-align: right;
}
@media (max-width: 767px) {
  .copyright__right {
    text-align: start;
  }
}
.copyright__right ul li a {
  margin: 0;
  color: var(--whiteColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .copyright__right ul li a {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .copyright__right ul li a {
    font-size: 14px;
  }
}
.copyright__right ul li a:hover {
  color: var(--primaryColor);
}
.copyright__arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .copyright__arrow {
    top: -10%;
  }
}
.copyright__arrow a {
  height: 52px;
  width: 52px;
  line-height: 52px;
  background: var(--whiteColor);
  color: var(--blackColor);
  font-weight: 700;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  font-size: 20px;
  margin: auto;
}
.copyright__arrow a:hover {
  background: var(--gradientColor);
  color: var(--whiteColor);
}

@media (max-width: 767px) {
  .copyright--4 .copyright__right {
    text-align: center;
  }
}

.footer__2 .footer__text p {
  color: var(--blackColor);
}
.footer__2 .footer__menu__title h6 {
  color: var(--blackColor);
}
.footer__2 .footer__menu__title h6:after {
  background: var(--blackColor);
}
.footer__2 .footer__menu ul li a {
  color: var(--blackColor);
}
.footer__2 .footer__menu ul li a:hover {
  color: var(--primaryColor);
}

.footer__4__small__text {
  margin-bottom: 15px;
}
.footer__4__small__text span {
  font-weight: 700;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .footer__4__small__text span {
    font-size: 14px;
  }
}
.footer__4__heading {
  margin-bottom: 35px;
}
.footer__4__heading h1 {
  font-size: 50px;
  color: var(--whiteColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer__4__heading h1 {
    font-size: 26px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .footer__4__heading h1 {
    font-size: 26px;
    line-height: 40px;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .footer__4__heading h1 {
    font-size: 20px;
    line-height: 36px;
  }
}
.footer__4__wrapper {
  text-align: center;
  text-align: center;
  max-width: 74%;
  margin: 0 auto;
}
@media (min-width: 320px) and (max-width: 575px) {
  .footer__4__wrapper {
    max-width: 90%;
  }
}

@media (max-width: 767px) {
  .copyright--4 {
    padding: 15px 0;
  }
}
@media (max-width: 767px) {
  .copyright--4 .copyright__left p {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .headerarea__icon.copyright__icon ul {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .headerarea__icon.copyright__icon ul li {
    display: block;
  }
}

.empty {
  width: 100%;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  opacity: 0.6;
  z-index: 10;
  font-size: 30px;
}
.cartarea__table__content .empty {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 10;
  font-size: 16px;
}
@media (min-width: 768) {
  .empty {
    font-size: 40px;
  }
  .cartarea__table__content .empty {
    font-size: 20px;
  }
}
.form-messege {
  color: var(--borderColor3);
}

.projects__tap__wrapper__img {
  width: 100%;
  height: 400px;
}
.projects__tap__wrapper__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
